import { Component, EventEmitter, Output } from '@angular/core'
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms'
import { CaseManagementService } from '../../services/case-management.service'
import { UserCredentials } from '../../shared/interfaces'
import { AfterViewInit } from '@angular/core'
import { Renderer2 } from '@angular/core'
import { Router } from '@angular/router'
import { HttpErrorResponse } from '@angular/common/http'
import { NgIf } from '@angular/common'
import { UserService } from '../../services/user.service'

@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   standalone: true,
   imports: [ReactiveFormsModule, NgIf],
})
export class LoginComponent implements AfterViewInit {
   constructor(
      private renderer: Renderer2,
      private userService: UserService
   ) {}

   // @Output() loginEvent = new EventEmitter<boolean>()
   displayedErrorMessage: string = ''

   ngAfterViewInit() {
      this.renderer.selectRootElement('#email').focus()
   }

   loginGroup = new FormGroup({
      email: new FormControl(''),
      password: new FormControl(''),
   })

   login() {
      this.displayedErrorMessage = ''
      let credentials = this.loginGroup.getRawValue() as UserCredentials
      this.userService.login(credentials)
   }

   releaseNote = [
      {
         date: 'Jan 26, 2025',
         desc: 'Authentication errors now display more helpful error messages.',
      },
      {
         date: 'Jan 25, 2025',
         desc: 'Release notes are now visible on the login page.',
      },
      {
         date: 'Jan 24, 2025',
         desc: 'Dropdown configuration has been condensed into one screen in the Admin Area.',
      },
      {
         date: 'Jan 23, 2025',
         desc: 'Administrators can now view the Platform Audit Trail in the Admin Area.',
      },
      {
         date: 'Jan 23, 2025',
         desc: 'Administrators can now view all configured Outcomes in the Admin Area.',
      },
      {
         date: 'Jan 02, 2025',
         desc: 'Fixed an issue where document upload failed in some instances.',
      },
      {
         date: 'Dec 23, 2024',
         desc: "Fixed an issue where administrators couldn't change a user's configured roles after initial account creation.",
      },
   ]
}
