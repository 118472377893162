import { Component } from '@angular/core'
import { SelectListComponent } from '../../../shared/components/controls/select-list/select-list.component'
import { InputTextComponent } from '../../../shared/components/controls/input-text/input-text.component'
import { InputNumberComponent } from '../../../shared/components/controls/input-number/input-number.component'
import { InputDateComponent } from '../../../shared/components/controls/input-date/input-date.component'
import { CheckboxGroupComponent } from '../../../shared/components/controls/checkbox-group/checkbox-group.component'
import { ButtonComponent } from '../../../shared/components/button/button.component'
import { DangerComponent } from '../../../shared/components/button/danger/danger.component'

@Component({
   selector: 'app-debug-components',
   standalone: true,
   imports: [
      SelectListComponent,
      InputTextComponent,
      InputNumberComponent,
      InputDateComponent,
      CheckboxGroupComponent,
      ButtonComponent,
      DangerComponent,
   ],
   templateUrl: './debug-custom-controls.component.html',
   styleUrl: './debug-custom-controls.component.css',
})
export class DebugCustomControls {}
