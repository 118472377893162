<app-action-tab>
   <app-button label="New Outcome" class="ngc-button-region"></app-button>
   <app-interactive-table
      [isProcessing]="$isProcessing"
      [columnConfiguration]="columnConfiguration"
      [tableData]="outcomeOptions"
      class="ngc-table-region"
   >
   </app-interactive-table>
</app-action-tab>
