import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CaseAuditEvent } from '../../interfaces'

@Component({
   selector: 'app-audit-trail-feed-row',
   standalone: true,
   imports: [CommonModule],
   templateUrl: './audit-trail-feed-row.component.html',
})
export class AuditTrailFeedRowComponent {
   @Input({ required: true }) auditEvent!: CaseAuditEvent
   jsonDisplayed = false
}
