<div id="adverseEventsTab">
   <div class="flex space-x-8">
      <div class="w-full">
         <!--Section Header-->
         <div class="flex justify-between py-2 border-b border-gray-100">
            <!--Title-->
            <div class="flex items-center">
               <p
                  class="text-base font-semibold leading-6 text-gray-900 align-middle"
               >
                  Complications
               </p>
            </div>

            <div class="flex flex-row space-x-1">
               <div>
                  <input
                     type="text"
                     name="adverse event filter"
                     id="adverse-event-filter"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                     placeholder="filter"
                  />
               </div>

               <!--Sort Menu-->
               <div class="inline-block text-left">
                  <div>
                     <button
                        type="button"
                        (click)="
                           adverseEventSortOptionsDisplayed =
                              !adverseEventSortOptionsDisplayed
                        "
                        class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        id="menu-button"
                        aria-expanded="true"
                        aria-haspopup="true"
                     >
                        Sort
                        <svg
                           class="-mr-1 h-5 w-5 text-gray-400"
                           viewBox="0 0 20 20"
                           fill="currentColor"
                           aria-hidden="true"
                        >
                           <path
                              fill-rule="evenodd"
                              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                              clip-rule="evenodd"
                           />
                        </svg>
                     </button>
                  </div>
                  @if (adverseEventSortOptionsDisplayed) {
                     <app-floating-options-menu
                        [options]="adverseEventOptions"
                     ></app-floating-options-menu>
                  }
               </div>
               @if (allowEditing) {
                  <button
                     type="button"
                     (click)="
                        this.selectedCaseAdverseEventID = 0;
                        $adverseEventFormIsDisplayed.set(true)
                     "
                     class="float-right inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                     New Complication
                  </button>
               }
            </div>
         </div>

         <div class="flex flex-row space-x-4">
            <!--Adverse Event List-->
            <div class="w-full">
               <app-simple-list
                  [listData]="adverseEventDisplayList"
                  (selectionEvent)="
                     selectedCaseAdverseEventID = $event.id;
                     $adverseEventFormIsDisplayed.set(true)
                  "
               >
               </app-simple-list>
            </div>

            @if ($adverseEventFormIsDisplayed()) {
               <app-adverse-event-form
                  [caseID]="caseID"
                  [$caseAdverseEvents]="$caseAdverseEvents"
                  [$formIsDisplayed]="$adverseEventFormIsDisplayed"
                  [selectedAdverseEventID]="selectedCaseAdverseEventID"
                  class="w-1/2"
               ></app-adverse-event-form>
            }
         </div>
      </div>
   </div>
</div>
