import { Component, signal, WritableSignal } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RegionDisplaySelector } from '../../shared/components/region-display-selector/region-display-selector.component'
import { RouterModule } from '@angular/router'
import { RegionDisplaySelectorTab } from '../../shared/interfaces'

@Component({
   selector: 'app-administration-page',
   standalone: true,
   imports: [CommonModule, RegionDisplaySelector, RouterModule],
   templateUrl: './administration-page.component.html',
})
export class AdministrationPageComponent {
   administrationTabs: WritableSignal<RegionDisplaySelectorTab[]> = signal([
      {
         key: 'users',
         displayValue: 'User Accounts',
         routerStrategy: 'relative',
         routerLink: 'users',
         isSelected: false,
      },
      {
         key: 'section-membership',
         displayValue: 'Section Security',
         routerStrategy: 'relative',
         routerLink: 'section-membership',
         isSelected: false,
      },
      {
         key: 'sections',
         displayValue: 'Sections',
         routerStrategy: 'relative',
         routerLink: 'sections',
         isSelected: true,
      },
      {
         key: 'roles',
         displayValue: 'Roles & Permissions',
         routerStrategy: 'relative',
         routerLink: 'roles',
         isSelected: false,
      },
      {
         key: 'dropdowns',
         displayValue: 'Dropdowns',
         routerStrategy: 'relative',
         routerLink: 'dropdowns',
         isSelected: false,
      },
      {
         key: 'complications',
         displayValue: 'Complications',
         routerStrategy: 'relative',
         routerLink: 'complications',
         isSelected: false,
      },
      {
         key: 'outcomes',
         displayValue: 'Outcomes',
         routerStrategy: 'relative',
         routerLink: 'outcomes',
         isSelected: false,
      },
      {
         key: 'failure-modes',
         displayValue: 'Failure Modes',
         routerStrategy: 'relative',
         routerLink: 'failure-modes',
         isSelected: false,
      },
      {
         key: 'platform-audit',
         displayValue: 'Audit Trail',
         routerStrategy: 'relative',
         routerLink: 'audit-trail', // TODO: audit/platform
         isSelected: false,
      },
      {
         key: 'license',
         displayValue: 'License',
         routerStrategy: 'relative',
         routerLink: 'license',
         isSelected: false,
      },
   ])
}
