<app-modal title="New User" [$isDisplayed]="$formIsDisplayed">
   <form [formGroup]="userForm">
      @if (userForm.get('userID')?.value) {
         <div class="flex flex-row">
            <app-input-number
               label="User ID"
               name="userID"
               formControlName="userID"
            ></app-input-number>
         </div>
      }

      <div class="flex flex-row space-x-1">
         <app-input-text
            label="First Name"
            name="firstName"
            formControlName="firstName"
         ></app-input-text>
         <app-input-text
            label="Last Name"
            name="lastName"
            formControlName="lastName"
         ></app-input-text>
      </div>

      <div class="flex flex-row space-x-1">
         <app-input-text
            label="Email"
            name="email"
            formControlName="email"
         ></app-input-text>
      </div>

      <div class="w-1/3">
         <app-toggle formControlName="isInstanceAdmin"></app-toggle>
      </div>
   </form>

   <div class="ngc-footer-right">
      <app-button label="Submit" (click)="submit()"></app-button>
   </div>
</app-modal>
