import { Component, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterLink } from '@angular/router'
import { Outcome, ReportTopic } from '../../../shared/interfaces'
import { BooleanPipePipe } from '../../../pipes/boolean-pipe.pipe'
import { HttpResponse } from '@angular/common/http'
import { downloadAsExcel } from '../../../shared/functions'
import { ZeroDatePipePipe } from '../../../pipes/zero-date.pipe'
import { ReportingService } from '../../../services/reporting.service'

@Component({
   selector: 'app-outcome-report',
   standalone: true,
   imports: [CommonModule, RouterLink, BooleanPipePipe, ZeroDatePipePipe],
   templateUrl: './outcome-report.component.html',
})
export class OutcomeReportComponent implements OnInit {
   constructor(private reportingService: ReportingService) {}
   caseOutcomes: Outcome[] = []
   ngOnInit() {
      this.reportingService.getManyOutcomes().subscribe({
         next: (value) => (this.caseOutcomes = value),
      })
   }
   download() {
      this.reportingService
         .downloadReportAsExcel(ReportTopic.Outcome)
         .subscribe({
            next: (value) => {
               if (value instanceof HttpResponse) {
                  downloadAsExcel(value, 'OutcomeReport')
               }
            },
         })
   }
}
