<!--TODO: This is a good modal template. Separate into an injectable title, footer, and content-->
<ng-container *ngIf="dataIsLoaded">
   <div
      class="relative z-30"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
   >
      <div
         class="fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity"
      ></div>

      <div
         class="relative transform overflow-hidden rounded-lg bg-white py-4 px-6 text-left shadow-xl transition-all"
      >
         <div class="border-b border-gray-200 pb-1.5">
            <h3 class="text-base font-semibold leading-6 text-gray-900">
               @if (isNewRecord(this.selectedActionItemID)) {
                  <span>New</span>
               } @else {
                  <span>Edit</span>
               }
               Action Item
            </h3>
         </div>
         <form [formGroup]="actionItemForm" class="overflow-scroll">
            <!--Title-->
            <div class="mt-2">
               <label
                  for="actionItemTitle"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Description</label
               >
               <div class="mt-2">
                  <textarea
                     formControlName="title"
                     name="title"
                     id="actionItemTitle"
                     rows="4"
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  ></textarea>
               </div>
            </div>

            <!--Status-->
            <div class="mt-2 ml-1">
               <label class="block text-sm font-medium leading-6 text-gray-900"
                  >Status</label
               >
               <p class="text-sm text-gray-500">
                  What's the current status of this action item?
               </p>
               <fieldset class="mt-4">
                  <legend class="sr-only">Action Item Status</legend>
                  <div class="space-y-2.5">
                     <div
                        *ngFor="let status of statusOptions"
                        class="flex items-center"
                     >
                        <input
                           id="actionItemStatus-{{ status.name }}"
                           formControlName="statusID"
                           type="radio"
                           class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                           [value]="status.id"
                        />
                        <label
                           for="actionItemStatus-{{ status.name }}"
                           class="ml-3 block text-sm font-medium leading-6 text-gray-900"
                           >{{ status.name }}</label
                        >
                     </div>
                  </div>
               </fieldset>
            </div>

            <div class="flex flex-row space-x-2 w-full">
               <!--Due Date-->
               <div class="mt-2">
                  <label
                     for="actionItemDueDate"
                     class="block text-sm font-medium leading-6 text-gray-900"
                     >Due</label
                  >
                  <div class="mt-2">
                     <input
                        type="date"
                        formControlName="dueDate"
                        name="dueDate"
                        id="actionItemDueDate"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                     />
                  </div>
               </div>

               <!--Completion Date-->
               <div class="my-2" [hidden]="!displayOptions.closeDateHidden">
                  <label
                     for="actionItemCompletionDate"
                     class="block text-sm font-medium leading-6 text-gray-900"
                     >Closed</label
                  >
                  <div class="mt-2">
                     <input
                        type="date"
                        formControlName="closeDate"
                        name="closeDate"
                        id="actionItemCompletionDate"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                     />
                  </div>
               </div>
            </div>

            <!--Assignees-->
            <div class="border-b border-gray-200 pt-4 pb-2">
               <div class="flex flex-row justify-between">
                  <h4 class="text-base font-semibold leading-6 text-gray-900">
                     Assignees
                  </h4>
                  <div
                     (click)="displayOptions.assigneeDropdownVisible = true"
                     class="cursor-pointer"
                  >
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                     >
                        <path
                           stroke-linecap="round"
                           stroke-linejoin="round"
                           d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                     </svg>
                  </div>
               </div>
            </div>

            <div class="mt-2">
               <div
                  *ngIf="actualAssignees.length > 0; else noAssignees"
                  class="py-1.5"
               >
                  <ul class="divide-y divide-dashed">
                     <li
                        *ngFor="let assignee of actualAssignees"
                        class="py-1.5"
                     >
                        <div class="flex flex-row justify-between">
                           <div class="flex flex-row">
                              <!--<img-->
                              <!--   class="h-10 w-10 rounded-full"-->
                              <!--   src="{{ assignee.profilePicPath }}"-->
                              <!--   alt="profile pic"-->
                              <!--/>-->
                              <div>
                                 <div
                                    class="text-sm font-medium leading-6 text-gray-900"
                                 >
                                    {{ assignee.name }}
                                    <span
                                       class="text-sm font-light text-gray-900"
                                    >
                                       ({{ assignee.email }})</span
                                    >
                                 </div>
                              </div>
                           </div>
                           <span
                              (click)="removeAssignee(assignee)"
                              class="cursor-pointer"
                           >
                              <svg
                                 xmlns="http://www.w3.org/2000/svg"
                                 fill="none"
                                 viewBox="0 0 24 24"
                                 stroke-width="1.5"
                                 stroke="currentColor"
                                 class="w-6 h-6"
                              >
                                 <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                 />
                              </svg>
                           </span>
                        </div>
                     </li>
                  </ul>
               </div>

               <ng-template #noAssignees>
                  <div class="text-sm text-gray-500 py-1.5">
                     There's no one assigned to this action item yet. Click
                     <span
                        (click)="displayOptions.assigneeDropdownVisible = true"
                        class="font-medium text-indigo-600 hover:underline"
                        >here</span
                     >
                     to add someone.
                  </div>
               </ng-template>

               <!--New Assignee Selection-->
               <div
                  *ngIf="displayOptions.assigneeDropdownVisible"
                  class="py-1.5 px-1"
               >
                  <!--<label for="assignees" class="block text-sm font-medium leading-6 text-gray-900">Add a new assignee</label>-->
                  <select
                     id="assignees"
                     name="assignees"
                     formControlName="newAssignee"
                     (change)="addAssignee()"
                     class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                     <option [ngValue]="null" class="text-gray-500 font-light">
                        Select an assignee...
                     </option>
                     <option
                        *ngFor="let pa of potentialAssignees"
                        [ngValue]="pa"
                     >
                        {{ pa.name }}
                     </option>
                  </select>
               </div>
            </div>

            <!--Footer-->
            <div class="border-t border-gray-200 pt-1.5">
               <div class="flex justify-between">
                  <div class="flex flex-row space-x-1">
                     <button
                        type="button"
                        (click)="cancel()"
                        class="rounded-md mt-2 bg-white px-2.5 py-1.5 text-sm text-gray-900 hover:ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                     >
                        Cancel
                     </button>
                     @if (!isNewRecord(this.selectedActionItemID)) {
                        <app-button-danger
                           [label]="'Delete'"
                           (click)="delete()"
                        ></app-button-danger>
                     }
                  </div>
                  <button
                     type="button"
                     (click)="submit()"
                     class="float-right mt-2 inline-flex items-center rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                     Save
                  </button>
               </div>
            </div>
         </form>
      </div>
   </div>
</ng-container>
