import { Component, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ActionItem, ReportTopic } from '../../../shared/interfaces'
import { RouterLink } from '@angular/router'
import { HttpResponse } from '@angular/common/http'
import { downloadAsExcel } from '../../../shared/functions'
import { ReportingService } from '../../../services/reporting.service'

@Component({
   selector: 'app-action-item-report',
   standalone: true,
   imports: [CommonModule, RouterLink],
   templateUrl: './action-item-report.component.html',
})
export class ActionItemReportComponent implements OnInit {
   constructor(private reportingService: ReportingService) {}

   actionItems: ActionItem[] = []

   ngOnInit() {
      this.reportingService.getManyActionItems().subscribe({
         next: (value) => {
            console.log(value)
            this.actionItems = value
         },
      })
   }

   download() {
      this.reportingService
         .downloadReportAsExcel(ReportTopic.ActionItem)
         .subscribe({
            next: (value) => {
               if (value instanceof HttpResponse) {
                  downloadAsExcel(value, 'ActionItemReport')
               }
            },
         })
   }
}
