import {
   Component,
   inject,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { AdminService } from '../../../services/admin.service'
import { Capability, InteractiveTableColumn } from '../../../shared/interfaces'
import { ActionTabComponent } from '../../../shared/components/layout/action-tab/action-tab.component'
import { InteractiveTableComponent } from '../../../shared/components/table/interactive-table.component'
import { ButtonComponent } from '../../../shared/components/button/button.component'

@Component({
   selector: 'app-admin-tab-outcomes',
   standalone: true,
   imports: [ActionTabComponent, InteractiveTableComponent, ButtonComponent],
   templateUrl: './admin-tab-outcomes.component.html',
})
export class AdminTabOutcomesComponent implements OnInit {
   adminService = inject(AdminService)
   outcomeOptions: Capability[] = []
   $isProcessing: WritableSignal<boolean> = signal(false)

   columnConfiguration: InteractiveTableColumn[] = [
      {
         key: 'id',
         dataType: 'number',
         displayValue: 'ID',
         sortIndex: 0,
         isVisible: true,
         headerAlignment: 'center',
         cellAlignment: 'center',
      },
      {
         key: 'name',
         dataType: 'string',
         displayValue: 'Name',
         sortIndex: 1,
         isVisible: true,
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
      {
         key: 'description',
         dataType: 'string',
         displayValue: 'Description',
         sortIndex: 2,
         isVisible: true,
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
      {
         key: 'abbreviation',
         dataType: 'string',
         displayValue: 'Abbreviation',
         sortIndex: 3,
         isVisible: true,
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
   ]
   ngOnInit() {
      this.$isProcessing.set(true)
      this.adminService.getCapabilities('outcome').subscribe({
         next: (value) => {
            this.outcomeOptions = value.slice(0)
         },
         complete: () => this.$isProcessing.set(false),
      })
   }
}
