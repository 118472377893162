import {
   ChangeDetectorRef,
   Component,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import {
   ClinicalSection,
   RegionDisplaySelectorTab,
} from '../../shared/interfaces'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { UserService } from '../../services/user.service'
import { RegionDisplaySelector } from '../../shared/components/region-display-selector/region-display-selector.component'
import { HomeTabNextConferenceComponent } from './home-tab-next-conference/home-tab-next-conference.component'
import { HomeTabRecentlyViewedComponent } from './home-tab-recently-viewed/home-tab-recently-viewed.component'
import { HomeTabOpenCasesComponent } from './home-tab-open-cases/home-tab-open-cases.component'
import { StorageService } from '../../services/storage.service'

@Component({
   selector: 'app-home',
   templateUrl: './home.component.html',
   standalone: true,
   imports: [
      ReactiveFormsModule,
      FormsModule,
      RegionDisplaySelector,
      HomeTabNextConferenceComponent,
      HomeTabRecentlyViewedComponent,
      HomeTabOpenCasesComponent,
   ],
})
export class HomeComponent implements OnInit {
   constructor(
      private userService: UserService,
      protected storageService: StorageService
   ) {}

   accessibleSections: ClinicalSection[] = []
   $selectedSectionID: WritableSignal<number> = signal(0)
   $activeTab: WritableSignal<string> = signal('')

   ngOnInit() {
      if (this.storageService.getLastViewedSection() !== null) {
         this.$selectedSectionID.set(this.storageService.getLastViewedSection())
      }
      this.accessibleSections = this.userService.getAccessibleSections() // Possible Sections for User
   }

   regionDisplayTabs: WritableSignal<RegionDisplaySelectorTab[]> = signal([
      {
         key: 'in-progress',
         displayValue: 'Open Cases',
         isSelected: true,
      },
      {
         key: 'next-conference',
         displayValue: 'Next Conference',
         isSelected: false,
      },
      {
         key: 'recent',
         displayValue: 'Recently Viewed',
         isSelected: false,
      },
   ])
}
