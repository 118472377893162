import { Component, signal, WritableSignal } from '@angular/core'
import { RegionDisplaySelectorTab } from '../../../shared/interfaces'
import { RouterModule } from '@angular/router'
import { RegionDisplaySelector } from '../../../shared/components/region-display-selector/region-display-selector.component'

@Component({
   selector: 'app-admin-tab-dropdowns',
   standalone: true,
   imports: [RouterModule, RegionDisplaySelector],
   templateUrl: './admin-tab-dropdowns.component.html',
})
export class AdminTabDropdownsComponent {
   regionTabs: WritableSignal<RegionDisplaySelectorTab[]> = signal([
      {
         key: 'levelOfPreventability',
         displayValue: 'Level of Preventability',
         routerStrategy: 'relative',
         routerLink: 'level-of-preventability',
         isSelected: true,
      },
      {
         key: 'changeInClinicalStatus',
         displayValue: 'Change in Clinical Status',
         routerStrategy: 'relative',
         routerLink: 'change-in-clinical-status',
         isSelected: false,
      },
      {
         key: 'categorization',
         displayValue: 'Categorization',
         routerStrategy: 'relative',
         routerLink: 'categorization',
         isSelected: false,
      },
   ])
}
