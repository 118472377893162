import { Component, Input, signal, WritableSignal } from '@angular/core'
import { DangerComponent } from '../button/danger/danger.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgForOf, NgIf } from '@angular/common'
import { ButtonComponent } from '../button/button.component'

/**
 * A reusable modal dialog component.
 *
 * ## Usage:
 * - Display a modal dialog with a customizable title and controlled visibility.
 * - Bind a `WritableSignal<boolean>` to the `$isDisplayed` input to control its visibility.
 * - The modal will handle hiding itself on cancellation, background click, or submission.
 * - A "cancel" button does not need injected; the modal provides one
 * ### Inputs:
 * - `title` (required): The title displayed at the top of the modal.
 * - `$isDisplayed` (required): A signal determining whether the modal is visible.
 *
 * ### Content Projection Regions:
 * By default, content is projected in the central scrollable modal region. Additional content
 * can be injected into the following regions, by class:
 * - .ngc-footer-left
 * - .ngc-footer-right
 */
@Component({
   selector: 'app-modal',
   standalone: true,
   imports: [FormsModule, ReactiveFormsModule, ButtonComponent],
   templateUrl: './modal.component.html',
})
export class ModalComponent {
   @Input({ required: true }) title: string = ''
   @Input({ required: true }) $isDisplayed: WritableSignal<boolean> =
      signal(false)

   cancel() {
      this.$isDisplayed.set(false)
   }
}
