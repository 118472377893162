import { Injectable } from '@angular/core'
import { ClinicalSection, MonarchCase } from '../shared/interfaces'

@Injectable({
   providedIn: 'root',
})
export class StorageService {
   private readonly recentlyViewedCasesStorageKey = 'recentlyViewedCases'
   private readonly maxItems = 10

   /**
    * Writes a Case to the recently viewed cases history in localStorage.
    * @param viewedCase The Case object that was viewed.
    * @returns True if the operation was successful, false otherwise.
    */
   writeToRecentlyViewedCaseHistory(viewedCase: MonarchCase): boolean {
      try {
         // Retrieve existing cases from localStorage
         const storedCases = localStorage.getItem(
            this.recentlyViewedCasesStorageKey
         )
         let cases: MonarchCase[] = storedCases ? JSON.parse(storedCases) : []

         // Remove the case if it already exists in the array
         cases = cases.filter((c) => c.caseID !== viewedCase.caseID)

         // Add the new case to the front of the array
         cases.unshift(viewedCase)

         // If the array exceeds the maximum allowed items, remove the last one
         if (cases.length > this.maxItems) {
            cases = cases.slice(0, this.maxItems)
         }

         // Save the updated array back to localStorage
         localStorage.setItem(
            this.recentlyViewedCasesStorageKey,
            JSON.stringify(cases)
         )

         return true
      } catch (error) {
         console.error('Error writing to recently viewed cases history:', error)
         return false
      }
   }

   /**
    * Retrieves the array of recently viewed Cases from localStorage.
    * @returns An array of Case objects, ordered from most recent to least recent.
    */
   getRecentlyViewedCases(sectionID: number): MonarchCase[] {
      try {
         const storedCases = localStorage.getItem(
            this.recentlyViewedCasesStorageKey
         )
         if (!storedCases) {
            // No cases stored yet
            return []
         }

         // Parse the stored JSON string into an array of Cases
         const cases: MonarchCase[] = JSON.parse(storedCases)

         // Optional: Validate the structure of each case
         if (!Array.isArray(cases)) {
            console.warn(
               'Recently viewed cases data is not an array. Resetting to empty array.'
            )
            return []
         }

         return cases.filter((c) => c.sectionID === sectionID)
      } catch (error) {
         console.error(
            'Error retrieving recently viewed cases from history:',
            error
         )
         // In case of error, return an empty array to avoid breaking the application
         return []
      }
   }

   setLastViewedSectionID(id: number): void {
      this.setNumericValue('lastViewedSectionID', id)
   }

   getLastViewedSection(): number {
      return this.getNumericValue('lastViewedSectionID')!
   }

   // Function to set a numeric value in local storage
   setNumericValue(key: string, value: number): void {
      if (!isNaN(value)) {
         localStorage.setItem(key, value.toString())
      } else {
         console.error('Value must be a valid number.')
      }
   }

   // Function to get a numeric value from local storage
   getNumericValue(key: string): number | null {
      const storedValue = localStorage.getItem(key)
      if (storedValue !== null) {
         const numericValue = Number(storedValue)
         if (!isNaN(numericValue)) {
            return numericValue
         } else {
            console.warn('Stored value is not a valid number.')
            return null
         }
      }
      return null // Return null if the key does not exist
   }
}
