import { inject, Injectable } from '@angular/core'
import {
   HttpClient,
   HttpEvent,
   HttpParams,
   HttpRequest,
   HttpResponse,
} from '@angular/common/http'
import { Observable } from 'rxjs'
import {
   ActionItem,
   AdverseEventAPIResponse,
   AdverseEvent,
   CaseAdverseEventInput,
   CreateCaseInputs,
   CaseFailureResponse,
   CaseLinkedEncounter,
   CaseLinkedProcedure,
   CreateOutcome,
   MonarchCase,
   Document,
   CaseFailure,
   CreateFailure,
   Outcome,
   CreateActionItem,
   CaseLinkedPractitioner,
   CaseAuditEvent,
} from '../shared/interfaces'
import { ConfigurationService } from './configuration.service'

@Injectable({
   providedIn: 'root',
})
export class CaseManagementService {
   endpoint = inject(ConfigurationService).getEndpoint()
   constructor(private http: HttpClient) {}

   getCase(caseID: number): Observable<MonarchCase> {
      return this.http.get<MonarchCase>(`${this.endpoint}/case/${caseID}`)
   }

   // TODO: Figure out which update func is the real one
   create(inputs: CreateCaseInputs): Observable<createdCase> {
      return this.http.post<createdCase>(
         `${this.endpoint}/case`,
         JSON.stringify(inputs)
      )
   }

   updateCase(caseID: number, data: any): Observable<MonarchCase> {
      return this.http.patch<MonarchCase>(
         `${this.endpoint}/case/${caseID}`,
         JSON.stringify(data)
      )
   }

   closeCase(caseID: number): Observable<any> {
      return this.http.patch(`${this.endpoint}/case/${caseID}/closeCase`, null)
   }

   getCasesForSections(
      sectionIDs: number[] | undefined
   ): Observable<MonarchCase[]> {
      if (sectionIDs !== undefined) {
         let params = new HttpParams()
         for (const id of sectionIDs) {
            params = params.append('sectionID', id)
            console.log('params is' + params)
         }
         return this.http.get<MonarchCase[]>(`${this.endpoint}/case`, {
            params: params,
         })
      }
      return this.http.get<MonarchCase[]>(`${this.endpoint}/case`)
   }

   getRelatedActionItems(caseID: number): Observable<ActionItem[]> {
      return this.http.get<ActionItem[]>(
         `${this.endpoint}/case/${caseID}/actionItem`
      )
   }

   // FIXME: Needs the /case/{caseID} added. API Refactored 1/25/24
   createActionItem(actionItem: CreateActionItem): Observable<ActionItem> {
      return this.http.post<ActionItem>(
         `${this.endpoint}/case/${actionItem.caseID}/actionItem`,
         actionItem
         // JSON.stringify(actionItem)
      )
   }

   getActionItem(caseID: number, actionItemID: number): Observable<ActionItem> {
      return this.http.get<ActionItem>(
         `${this.endpoint}/case/${caseID}/actionItem/${actionItemID}`
      )
   }

   updateActionItem(
      caseID: number,
      actionItemID: number,
      actionItem: CreateActionItem
   ): Observable<ActionItem> {
      return this.http.patch<ActionItem>(
         `${this.endpoint}/case/${caseID}/actionItem/${actionItemID}`,
         JSON.stringify(actionItem)
      )
   }

   deleteActionItem(caseID: number, actionItemID: number) {
      return this.http.delete(
         `${this.endpoint}/case/${caseID}/actionItem/${actionItemID}`
      )
   }

   createAdverseEvent(
      caseComplication: CaseAdverseEventInput
   ): Observable<AdverseEvent> {
      return this.http.post<AdverseEvent>(
         `${this.endpoint}/case/${caseComplication.caseID}/adverseEvent`,
         JSON.stringify(caseComplication)
      )
   }

   getAdverseEvent(
      caseID: number,
      complicationID: number
   ): Observable<AdverseEvent> {
      return this.http.get<AdverseEvent>(
         `${this.endpoint}/case/${caseID}/adverseEvent/${complicationID}`
      )
   }

   updateAdverseEvent(input: {
      caseID: number
      caseComplicationID: number
      note: string
   }): Observable<AdverseEvent> {
      return this.http.patch<AdverseEvent>(
         `${this.endpoint}/case/${input.caseID}/adverseEvent/${input.caseComplicationID}`,
         JSON.stringify(input)
      )
   }

   deleteAdverseEvent(
      caseID: number,
      caseComplicationID: number
   ): Observable<Object> {
      return this.http.delete(
         `${this.endpoint}/case/${caseID}/adverseEvent/${caseComplicationID}`
      )
   }

   getOutcome(caseID: number, outcomeID: number): Observable<Outcome> {
      return this.http.get<Outcome>(
         `${this.endpoint}/case/${caseID}/outcome/${outcomeID}`
      )
   }

   updateOutcome(input: {
      caseID: number
      caseOutcomeID: number
      note: string
   }): Observable<Outcome> {
      return this.http.patch<Outcome>(
         `${this.endpoint}/case/${input.caseID}/outcome/${input.caseOutcomeID}`,
         JSON.stringify(input)
      )
   }

   createOutcome(outcome: CreateOutcome): Observable<Outcome> {
      return this.http.post<Outcome>(
         `${this.endpoint}/case/${outcome.caseID}/outcome`,
         JSON.stringify(outcome)
      )
   }

   deleteOutcome(caseID: number, caseOutcomeID: number): Observable<Object> {
      return this.http.delete(
         `${this.endpoint}/case/${caseID}/outcome/${caseOutcomeID}`
      )
   }

   getRelatedDocuments(caseID: number): Observable<Document[]> {
      return this.http.get<Document[]>(
         `${this.endpoint}/case/${caseID}/document`
      )
   }

   uploadDocument(caseID: number, input: FormData): Observable<Document[]> {
      return this.http.post<Document[]>(
         `${this.endpoint}/case/${caseID}/document`,
         input
      )
   }

   downloadDocument(caseID: number, fileGUID: string) {
      const request = new HttpRequest(
         'GET',
         `${this.endpoint}/case/${caseID}/document/${fileGUID}`,
         {
            observe: 'response',
            responseType: 'blob',
         }
      )
      return this.http.request(request)
   }

   createFailure(
      caseID: number,
      input: CreateFailure
   ): Observable<CaseFailure> {
      return this.http.post<CaseFailure>(
         `${this.endpoint}/case/${caseID}/failureMode`,
         JSON.stringify(input)
      )
   }

   updateFailure(
      caseID: number,
      caseFailureID: number,
      note: string
   ): Observable<CaseFailure> {
      return this.http.patch<CaseFailure>(
         `${this.endpoint}/case/${caseID}/failureMode/${caseFailureID}`,
         JSON.stringify({ note: note })
      )
   }

   deleteFailure(
      caseID: number,
      caseFailureID: number
   ): Observable<HttpResponse<any>> {
      return this.http.delete<HttpResponse<any>>(
         `${this.endpoint}/case/${caseID}/failureMode/${caseFailureID}`
      )
   }

   getFailure(caseID: number, caseFailureID: number): Observable<CaseFailure> {
      return this.http.get<CaseFailure>(
         `${this.endpoint}/case/${caseID}/failureMode/${caseFailureID}`
      )
   }

   getRelatedAdverseEvents(caseID: number): Observable<AdverseEvent[]> {
      return this.http.get<AdverseEvent[]>(
         `${this.endpoint}/case/${caseID}/adverseEvent`
      )
   }

   getRelatedOutcomes(caseID: number): Observable<Outcome[]> {
      return this.http.get<Outcome[]>(`${this.endpoint}/case/${caseID}/outcome`)
   }

   getManyAdverseEvents2(): Observable<AdverseEventAPIResponse[]> {
      return this.http.get<AdverseEventAPIResponse[]>(
         `${this.endpoint}/report/adverseEvent`
      )
   }

   getRelatedFailures(caseID: number): Observable<CaseFailureResponse[]> {
      return this.http.get<CaseFailureResponse[]>(
         `${this.endpoint}/case/${caseID}/failureMode`
      )
   }

   getRelatedEncounters(caseID: number): Observable<CaseLinkedEncounter[]> {
      return this.http.get<CaseLinkedEncounter[]>(
         `${this.endpoint}/case/${caseID}/encounter`
      )
   }

   linkEncounterToCase(
      caseID: number,
      encounterFhirID: string
   ): Observable<CaseLinkedEncounter> {
      let payload = JSON.stringify({
         caseID: caseID,
         encounterFhirID: encounterFhirID,
      })
      return this.http.post<CaseLinkedEncounter>(
         `${this.endpoint}/case/${caseID}/encounter`,
         payload
      )
   }

   getRelatedProcedures(caseID: number): Observable<CaseLinkedProcedure[]> {
      return this.http.get<CaseLinkedProcedure[]>(
         `${this.endpoint}/case/${caseID}/procedure`
      )
   }

   linkProcedureToCase(caseID: number, procedureFhirID: string) {
      let payload = JSON.stringify({
         caseID: caseID,
         procedureFhirID: procedureFhirID,
      })
      return this.http.post<CaseLinkedProcedure>(
         `${this.endpoint}/case/${caseID}/procedure`,
         payload
      )
   }

   getRelatedPractitioners(
      caseID: number
   ): Observable<CaseLinkedPractitioner[]> {
      return this.http.get<CaseLinkedPractitioner[]>(
         `${this.endpoint}/case/${caseID}/practitioner`
      )
   }

   linkPractitionerToCase(caseID: number, practitionerFhirID: string) {
      let payload = JSON.stringify({
         caseID: caseID,
         practitionerFhirID: practitionerFhirID,
      })
      return this.http.post<CaseLinkedPractitioner>(
         `${this.endpoint}/case/${caseID}/practitioner`,
         payload
      )
   }

   getAuditTrail(caseID: number): Observable<CaseAuditEvent[]> {
      return this.http.get<CaseAuditEvent[]>(
         `${this.endpoint}/case/${caseID}/audit`
      )
   }
}

interface createdCase {
   createdCaseID: number
}
