import { Component, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CaseManagementService } from '../../../services/case-management.service'
import { RouterLink } from '@angular/router'
import { HttpResponse } from '@angular/common/http'
import { downloadAsExcel } from '../../../shared/functions'
import { ZeroDatePipePipe } from '../../../pipes/zero-date.pipe'
import { ReportingService } from '../../../services/reporting.service'
import {
   AdverseEventAPIResponse,
   ReportTopic,
} from '../../../shared/interfaces'

@Component({
   selector: 'app-adverse-event-report',
   standalone: true,
   imports: [CommonModule, RouterLink, ZeroDatePipePipe],
   templateUrl: './adverse-event-report.component.html',
})
export class AdverseEventReportComponent implements OnInit {
   constructor(
      private caseManagementService: CaseManagementService,
      private reportingService: ReportingService
   ) {}

   adverseEvents: AdverseEventAPIResponse[] = []

   ngOnInit() {
      this.caseManagementService.getManyAdverseEvents2().subscribe({
         next: (value) => {
            this.adverseEvents = value
         },
      })
   }

   download() {
      this.reportingService
         .downloadReportAsExcel(ReportTopic.AdverseEvent)
         .subscribe({
            next: (value) => {
               if (value instanceof HttpResponse) {
                  downloadAsExcel(value, 'AdverseEventReport')
               }
            },
         })
   }
}
