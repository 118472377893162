<div>
   <div class="flex flex-row items-center space-x-4">
      <p class="text-2xl font-bold leading-7 text-gray-900 py-1.5">
         Complications
      </p>
      <svg
         xmlns="http://www.w3.org/2000/svg"
         fill="none"
         viewBox="0 0 24 24"
         stroke-width="1.5"
         stroke="currentColor"
         class="w-6 h-6"
      >
         <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
         />
      </svg>
      <img
         src="../../../../assets/excel.png"
         alt="download to excel"
         class="w-6 h-6"
         (click)="download()"
      />
   </div>
</div>

<div class="overflow-scroll my-4">
   <table class="min-w-full border border-spacing-0 divide-y divide-gray-300">
      <thead>
         <tr class="divide-x divide-gray-200">
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-1.5 backdrop-blur backdrop-filter"
            >
               <span class="sr-only">View Case</span>
            </th>
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter whitespace-nowrap"
            >
               Patient
            </th>
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter whitespace-nowrap"
            >
               MRN
            </th>
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter whitespace-nowrap"
            >
               Section
            </th>
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter whitespace-nowrap"
            >
               Conference Date
            </th>
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter whitespace-nowrap"
            >
               Case Status
            </th>
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter whitespace-nowrap"
            >
               Category
            </th>
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
            >
               Complication
            </th>
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
            >
               Note
            </th>
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
            >
               Description
            </th>
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
            >
               Created
            </th>
            <th
               scope="col"
               class="sticky top-0 z-10 border-b border-gray-300 bg-gray-100 bg-opacity-75 py-1.5 px-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter whitespace-nowrap"
            >
               Created By
            </th>
         </tr>
      </thead>

      <tbody class="divide-y divide-gray-200">
         <tr *ngFor="let i of adverseEvents" class="divide-x divide-gray-200">
            <td
               class="relative whitespace-nowrap border-b border-gray-200 py-4 pr-4 pl-3 text-right text-sm font-medium"
            >
               <a
                  href="#"
                  class="text-indigo-600 hover:text-indigo-900"
                  routerLink="/case/{{ i.caseID }}"
                  >View Case</a
               >
            </td>
            <td
               class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800 text-left"
            >
               {{ i.linkedCase.patientName }}
            </td>
            <td
               class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800 text-left"
            >
               {{ i.linkedCase.patientMRN }}
            </td>
            <td
               class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800 text-left"
            >
               {{ i.linkedCase.sectionName }}
            </td>
            <td
               class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800 text-left"
            >
               {{ i.linkedCase.conferenceDate | zeroDatePipe | date }}
            </td>
            <td
               class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800 text-left"
            >
               {{ i.linkedCase.isOpen }}
            </td>
            <td
               class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800 text-left"
            >
               {{ i.complicationCategory }}
            </td>
            <td
               class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800 text-left max-w-xl"
            >
               {{ i.complicationName }}
            </td>
            <td
               class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800"
            >
               {{ i.note }}
            </td>
            <td
               class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800"
            >
               {{ i.complicationDescription }}
            </td>
            <td
               class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800"
            >
               {{ i.createdAt | date }}
            </td>
            <td
               class="whitespace-nowrap border-b border-gray-200 px-3 py-4 text-sm text-gray-800"
            >
               {{ i.createdByUserName }}
            </td>
         </tr>
      </tbody>
   </table>
</div>
