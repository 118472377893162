import { Component, Input, signal, WritableSignal } from '@angular/core'
import { ButtonComponent } from '../../button/button.component'
import { InteractiveTableComponent } from '../../table/interactive-table.component'
import { SectionFormComponent } from '../../../../pages/administration/admin-tab-sections/section-form/section-form.component'

@Component({
   selector: 'app-action-tab',
   standalone: true,
   imports: [ButtonComponent, InteractiveTableComponent, SectionFormComponent],
   templateUrl: './action-tab.component.html',
})
export class ActionTabComponent {
   @Input() $formIsDisplayed: WritableSignal<boolean> = signal(false)
}
