import {
   Component,
   inject,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { ButtonComponent } from '../../../shared/components/button/button.component'
import { UserFormComponent } from './user-form/user-form.component'
import { UserSecurityReportComponent } from '../../../views/user-security-report/user-security-report.component'
import { ActionTabComponent } from '../../../shared/components/layout/action-tab/action-tab.component'
import { InteractiveTableComponent } from '../../../shared/components/table/interactive-table.component'
import { InteractiveTableColumn, User } from '../../../shared/interfaces'
import { AdminService } from '../../../services/admin.service'

@Component({
   selector: 'app-admin-tab-users',
   standalone: true,
   imports: [
      ButtonComponent,
      UserFormComponent,
      UserSecurityReportComponent,
      ActionTabComponent,
      InteractiveTableComponent,
   ],
   templateUrl: './admin-tab-users.component.html',
})
// FIXME: Does not actually implement the user report. Mocked
export class AdminTabUsersComponent implements OnInit {
   $modalUserFormDisplayed: WritableSignal<boolean> = signal(false)
   $isProcessing: WritableSignal<boolean> = signal(false)
   adminService = inject(AdminService)
   users: User[] = []

   ngOnInit() {
      this.$isProcessing.set(true)
      this.adminService.getManyUsers().subscribe({
         next: (value) => (this.users = value.slice(0)),
         complete: () => this.$isProcessing.set(false),
      })
   }

   cplumns: InteractiveTableColumn[] = [
      {
         key: 'userID',
         displayValue: 'User ID',
         dataType: 'number',
         sortIndex: 0,
         isVisible: true,
      },
      {
         key: 'email',
         displayValue: 'Email',
         dataType: 'string',
         sortIndex: 0,
         isVisible: true,
      },
      {
         key: 'name',
         displayValue: 'Name',
         dataType: 'string',
         sortIndex: 0,
         isVisible: true,
      },
      {
         key: 'isInstanceAdmin',
         displayValue: 'Instance Administrator?',
         dataType: 'string',
         sortIndex: 0,
         isVisible: true,
      },
      {
         key: 'createdAt',
         displayValue: 'Created',
         dataType: 'datetime',
         sortIndex: 0,
         isVisible: true,
      },
      {
         key: 'accountStatus',
         displayValue: 'Status',
         dataType: 'string',
         sortIndex: 0,
         isVisible: true,
      },
   ]
}
