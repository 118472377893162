import { inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import {
   Capability,
   ClinicalSection,
   FailureModeTaxonomy,
   InstanceRoleConfiguration,
   PlatformAuditEvent,
   SectionMember,
   SectionUserPermission,
} from '../shared/interfaces'
import { Complication, User } from '../shared/interfaces'
import { HttpClient } from '@angular/common/http'
import { ConfigurationService } from './configuration.service'

@Injectable({
   providedIn: 'root',
})
export class AdminService {
   endpoint = inject(ConfigurationService).getEndpoint()
   constructor(private http: HttpClient) {}
   getCapabilities(concept: string): Observable<Capability[]> {
      return this.http.get<Capability[]>(
         `${this.endpoint}/options/capability?concept=${concept}`
      )
   }
   getManyUsers(): Observable<User[]> {
      return this.http.get<User[]>(`${this.endpoint}/user`)
   }

   getAdverseEventOptions(): Observable<Complication[]> {
      return this.http.get<Complication[]>(
         `${this.endpoint}/options/complication`
      )
   }
   getFailureModeTaxonomy(): Observable<FailureModeTaxonomy> {
      return this.http.get<FailureModeTaxonomy>(
         `${this.endpoint}/options/failureModeTaxonomy`
      )
   }

   getInstanceRoles(): Observable<InstanceRoleConfiguration> {
      return this.http.get<InstanceRoleConfiguration>(
         `${this.endpoint}/admin/role`
      )
   }

   getAllSections(): Observable<ClinicalSection[]> {
      return this.http.get<ClinicalSection[]>(`${this.endpoint}/admin/section`)
   }

   createSection(input: { sectionName: string }): Observable<ClinicalSection> {
      return this.http.post<ClinicalSection>(
         `${this.endpoint}/admin/section`,
         JSON.stringify(input)
      )
   }

   addSectionMember(
      sectionID: number,
      input: {
         userID: number
         roleIDs: number[]
      }
   ): Observable<SectionMember> {
      return this.http.post<SectionMember>(
         `${this.endpoint}/admin/section/${sectionID}/member`,
         JSON.stringify(input)
      )
   }

   getAllComplicationOptions(): Observable<Complication[]> {
      return this.http.get<Complication[]>(
         `${this.endpoint}/options/complication`
      )
   }

   getUserSecurityMatrix(): Observable<SectionUserPermission[]> {
      return this.http.get<SectionUserPermission[]>(
         `${this.endpoint}/admin/userPermissions`
      )
   }

   getAllAuditEvents(): Observable<PlatformAuditEvent[]> {
      return this.http.get<PlatformAuditEvent[]>(
         `${this.endpoint}/admin/audit/platform`
      )
   }
}
