<app-modal [title]="'Edit User Security'" [$isDisplayed]="formIsDisplayed">
   <form [formGroup]="sectionUserSecurityForm">
      <div class="flex flex-col space-y-4 mt-2">
         <app-select-list
            name="selectedUserID"
            label="User"
            formControlName="userID"
            [options]="users().options"
         ></app-select-list>

         @if (sectionUserSecurityForm.get('userID')?.value) {
            <app-select-list
               name="selectedSectionID"
               label="Modify Roles in which Section?"
               formControlName="sectionID"
               [options]="sections().options"
            ></app-select-list>
         }

         @if (sectionUserSecurityForm.get('sectionID')?.value) {
            <app-checkbox-group
               label="Roles"
               formControlName="roleIDs"
               [options]="roles().options"
            ></app-checkbox-group>
         }
      </div>
   </form>
   <div class="ngc-footer-right">
      <app-button label="Submit" (click)="onSubmit()"></app-button>
   </div>
</app-modal>
