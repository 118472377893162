<!--Background Opacity-->
<div
   class="relative z-30"
   aria-labelledby="modal-title"
   role="dialog"
   aria-modal="true"
>
   <div
      class="fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity"
      (click)="cancel()"
   ></div>

   <!--Modal-->
   <div
      class="relative flex flex-col transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all min-h-96 max-h-full"
   >
      <div class="border-b border-gray-200 pb-1.5">
         <h3 class="text-base font-semibold leading-6 text-gray-900">
            {{ title }}
         </h3>
      </div>

      <!--Scrollable interior region-->
      <div class="flex-1 overflow-y-auto">
         <ng-content> </ng-content>
      </div>

      <!--Fixed bottom bar button container-->
      <div class="absolute bottom-0 left-0 right-0 mt-2 pb-4 px-4">
         <div class="flex justify-between">
            <div class="flex flex-row space-x-1">
               <app-button
                  (click)="cancel()"
                  label="Cancel"
                  buttonType="secondary"
               ></app-button>
               <ng-content select=".ngc-footer-left"></ng-content>
            </div>

            <div class="flex flex-row-reverse space-x-1">
               <ng-content select=".ngc-footer-right"></ng-content>
            </div>
         </div>
      </div>
   </div>
</div>
