import { Component, forwardRef, Input } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
   selector: 'app-checkbox-group',
   standalone: true,
   imports: [],
   templateUrl: './checkbox-group.component.html',
   styleUrl: './checkbox-group.component.css',
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => CheckboxGroupComponent),
         multi: true, // Allows Angular to treat this as a custom form control
      },
   ],
})
export class CheckboxGroupComponent implements ControlValueAccessor {
   @Input() label: string | null = null
   @Input() options: CheckboxOption[] = [] // Array of options
   selectedValues: number[] = [] // Array of selected values

   private onChange: (value: number[]) => void = () => {}
   private onTouched: () => void = () => {}

   // Called by Angular to write a value to the component
   writeValue(value: number[]): void {
      this.selectedValues = value || []
   }

   // Registers a callback function when the value changes
   registerOnChange(fn: (value: number[]) => void): void {
      this.onChange = fn
   }

   // Registers a callback function when the component is touched
   registerOnTouched(fn: () => void): void {
      this.onTouched = fn
   }

   // Toggle the selection of a checkbox
   toggleSelection(value: number): void {
      if (this.selectedValues.includes(value)) {
         this.selectedValues = this.selectedValues.filter((v) => v !== value)
      } else {
         this.selectedValues.push(value)
      }
      this.onChange(this.selectedValues) // Notify the parent form control
      this.onTouched() // Mark as touched
   }

   // Check if a value is selected
   isChecked(value: number): boolean {
      return this.selectedValues.includes(value)
   }
}

export interface CheckboxOption {
   value: number
   label: string
   description?: string
}
