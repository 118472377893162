import {
   Component,
   EventEmitter,
   inject,
   Input,
   OnInit,
   Output,
   signal,
   WritableSignal,
} from '@angular/core'
import { NgClass } from '@angular/common'
import { RegionDisplaySelectorTab } from '../../interfaces'
import { ActivatedRoute, Router } from '@angular/router'

@Component({
   selector: 'app-region-display-selector',
   standalone: true,
   imports: [NgClass],
   templateUrl: './region-display-selector.component.html',
})

/**
 * @description
 * RegionDisplaySelector implements a horizontal list of tabs that are used to select or navigate to individual components.
 * This component can trigger navigation events and is often paired with a router-outlet
 * @usageNotes
 * - If a RegionDisplaySelectorTab's URL matches the current URL, that tab will be selected; otherwise the first tab will be selected onInit
 * - When a tab is selected, it will be emitted by (onTabChange)
 * - If a tab has a routerLink configured, this component will handle navigation; otherwise, it's up to the parent component to respond to
 * tab changes
 * @property tabs - The tabs to display, with metadata for selection and navigation.
 * @event onTabChange - Emits the selected tab whenever a user clicks on a tab.
 *  */
export class RegionDisplaySelector implements OnInit {
   router = inject(Router)
   route = inject(ActivatedRoute)

   @Input() tabs: WritableSignal<RegionDisplaySelectorTab[]> = signal([])
   @Input() isVertical: boolean = false
   @Output() onTabChange = new EventEmitter<RegionDisplaySelectorTab>()

   ngOnInit() {
      if (this.tabs().length > 0) {
         const activeTab = this.getActiveTabFromURL()
         if (activeTab) {
            this.selectTab(activeTab)
         } else {
            this.selectTab(this.tabs()[0])
         }
      }
   }

   selectTab(tab: RegionDisplaySelectorTab) {
      this.updateSelectedTab(tab)
      if (tab.routerLink) {
         this.navigateToTab(tab)
      }
      this.onTabChange.emit(tab)
   }

   private updateSelectedTab(tab: RegionDisplaySelectorTab) {
      this.tabs.update((items) =>
         items.map((item) => ({
            ...item,
            isSelected: item.key === tab.key,
         }))
      )
   }

   private navigateToTab(tab: RegionDisplaySelectorTab) {
      const navigationOptions =
         tab.routerStrategy === 'relative'
            ? { relativeTo: this.route }
            : undefined
      this.router.navigate([tab.routerLink], navigationOptions)
   }

   private getActiveTabFromURL(): RegionDisplaySelectorTab | null {
      const currentURL = this.router.url
      return (
         this.tabs().find(
            (tab) => tab.routerLink && currentURL.includes(tab.routerLink)
         ) || null
      )
   }
}
