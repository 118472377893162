<app-action-tab>
   <h3
      class="ngc-header-region font-semibold text-base text-gray-700 mr-4 py-1.5"
   >
      Categorization
   </h3>
   <app-button
      label="New Dropdown Value"
      class="ngc-button-region"
   ></app-button>

   <app-interactive-table
      [columnConfiguration]="standardizedColumns"
      [tableData]="categorizationOptions"
      [isProcessing]="$categorizationIsProcessing"
      class="ngc-table-region"
   ></app-interactive-table>
</app-action-tab>
