import {
   Component,
   inject,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import {
   InteractiveTableColumn,
   SectionUserPermission,
} from '../../../shared/interfaces'
import { InteractiveTableComponent } from '../../../shared/components/table/interactive-table.component'
import { ButtonComponent } from '../../../shared/components/button/button.component'
import { SectionFormComponent } from '../admin-tab-sections/section-form/section-form.component'
import { SectionUserSecurityFormComponent } from './section-user-security/section-user-security-form.component'
import { ActionTabComponent } from '../../../shared/components/layout/action-tab/action-tab.component'
import { AdminService } from '../../../services/admin.service'

@Component({
   selector: 'app-admin-tab-security',
   standalone: true,
   imports: [
      InteractiveTableComponent,
      ButtonComponent,
      SectionFormComponent,
      SectionUserSecurityFormComponent,
      ActionTabComponent,
   ],
   templateUrl: './admin-tab-security.component.html',
})
export class AdminTabSecurityComponent implements OnInit {
   adminService = inject(AdminService)

   $formIsDisplayed: WritableSignal<boolean> = signal(false)
   $isProcessing: WritableSignal<boolean> = signal(false)
   userPermissions: SectionUserPermission[] = []

   ngOnInit() {
      this.$isProcessing.set(true)
      this.adminService.getUserSecurityMatrix().subscribe({
         next: (value) => {
            this.userPermissions = value.slice(0).map((permission) => ({
               ...permission,
               editLink: 'edit',
            }))
         },

         complete: () => this.$isProcessing.set(false),
      })
   }

   testSectionAddition() {
      this.adminService
         .addSectionMember(1, {
            userID: 1,
            roleIDs: [1, 4],
         })
         .subscribe({
            next: (value) => {
               console.log(value)
            },
         })
   }

   columns: InteractiveTableColumn[] = [
      {
         key: 'sectionID',
         displayValue: 'Section ID',
         dataType: 'string',
         isVisible: true,
         sortIndex: 0,
         headerAlignment: 'center',
         cellAlignment: 'center',
      },
      {
         key: 'sectionName',
         displayValue: 'Section',
         dataType: 'string',
         isVisible: true,
         sortIndex: 0,
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
      {
         key: 'email',
         displayValue: 'Email',
         dataType: 'string',
         isVisible: true,
         sortIndex: 0,
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
      {
         key: 'userID',
         displayValue: 'User ID',
         dataType: 'number',
         isVisible: true,
         sortIndex: 0,
         headerAlignment: 'center',
         cellAlignment: 'center',
      },
      {
         key: 'firstName',
         displayValue: 'First Name',
         dataType: 'string',
         isVisible: true,
         sortIndex: 0,
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
      {
         key: 'roles',
         displayValue: 'Section Roles',
         dataType: 'list',
         isVisible: true,
         sortIndex: 0,
         headerAlignment: 'left',
      },
      {
         key: 'editLink',
         displayValue: 'Edit User Permissions',
         dataType: 'internalURL',
         isVisible: true,
         sortIndex: 0,
         headerAlignment: 'left',
         cellAlignment: 'center',
         routeConfig: {
            path: 'administration/section-membership/edit',
            params: { userID: 'userID', sectionID: 'sectionID' },
            displayValue: 'Edit Roles',
         },
      },
   ]
}
