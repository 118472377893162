<div class="min-w-48">
   <label
      for="{{ generatedID }}"
      class="block text-sm font-medium leading-6 text-gray-900"
      >{{ label }}</label
   >
   <div class="mt-1">
      <input
         type="date"
         [value]="value"
         (input)="handleChange($event)"
         (blur)="handleBlur()"
         name="{{ name }}"
         id="{{ generatedID }}"
         autocomplete="off"
         class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
   </div>
</div>
