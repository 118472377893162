export enum formMode {
   CREATE = 'Create',
   EDIT = 'Edit',
}

export enum formAction {
   CREATE,
   UPDATE,
   CANCEL,
   DELETE,
}

export enum WindowEvent {
   Cancel,
   Save,
   SaveAndClose,
}

export enum caseNavigationTabList {
   OVERVIEW = 'Case Overview',
   ENCOUNTERS = 'Encounters',
   PROCEDURES = 'Procedures',
   STAFF = 'Providers',
   ADVERSE_EVENTS = 'Complications',
   OUTCOMES = 'Outcomes',
   DISCUSSION = 'Discussion',
   FAILURE_MODES = 'Failure Modes',
   ACTION_ITEMS = 'Action Items',
   DOCUMENTS = 'Documents',
   ROUTING = 'Routing',
   AUDIT = 'Audit Trail',
}

export enum adminNavigationTabList {
   SECTIONS = 'sections',
   ROLES = 'roles',
   USERS = 'users',
   SECURITY = 'section-membership',
   DROPDOWNS = 'dropdowns',
   COMPLICATIONS = 'complications',
   OUTCOMES = 'outcomes',
   FAILURE_MODE_TAXONOMY = 'failure-modes',
   LICENSE = 'license',
}
