import {
   Component,
   inject,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { AdminService } from '../../../../services/admin.service'
import {
   InteractiveTableColumn,
   PlatformAuditEvent,
} from '../../../../shared/interfaces'
import { ActionTabComponent } from '../../../../shared/components/layout/action-tab/action-tab.component'
import { InteractiveTableComponent } from '../../../../shared/components/table/interactive-table.component'
import { audit } from 'rxjs'

@Component({
   selector: 'app-admin-tab-audit',
   standalone: true,
   imports: [ActionTabComponent, InteractiveTableComponent],
   templateUrl: './admin-tab-audit.component.html',
})
export class AdminTabAuditComponent implements OnInit {
   $isProcessing: WritableSignal<boolean> = signal(false)
   auditEvents: PlatformAuditEvent[] = []
   tableColumns: InteractiveTableColumn[] = [
      {
         key: 'event.ID',
         dataType: 'number',
         displayValue: 'Audit ID',
         sortIndex: 0,
         isVisible: true,
      },
      {
         key: 'event.type',
         dataType: 'string',
         displayValue: 'Event',
         sortIndex: 1,
         isVisible: true,
      },
      {
         key: 'event.timestamp',
         dataType: 'datetime',
         displayValue: 'Timestamp',
         sortIndex: 2,
         isVisible: true,
      },
      {
         key: 'user.userID',
         dataType: 'number',
         displayValue: 'User ID',
         sortIndex: 3,
         isVisible: true,
      },
      {
         key: 'user.email',
         dataType: 'string',
         displayValue: 'User Email',
         sortIndex: 4,
         isVisible: true,
      },
      {
         key: 'user.firstName',
         dataType: 'string',
         displayValue: 'First Name',
         sortIndex: 5,
         isVisible: true,
      },
      {
         key: 'user.lastName',
         dataType: 'string',
         displayValue: 'Last Name',
         sortIndex: 6,
         isVisible: true,
      },
      {
         key: 'user.state',
         dataType: 'string',
         displayValue: 'Current Account State',
         sortIndex: 7,
         isVisible: true,
      },
   ]
   adminService = inject(AdminService)
   ngOnInit() {
      this.$isProcessing.set(true)
      this.adminService.getAllAuditEvents().subscribe({
         next: (value) => (this.auditEvents = value.slice(0)),
         complete: () => this.$isProcessing.set(false),
      })
   }

   protected readonly audit = audit
}
