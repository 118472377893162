import { Component, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CaseManagementService } from '../../../services/case-management.service'
import { RouterLink } from '@angular/router'
import { BooleanPipePipe } from '../../../pipes/boolean-pipe.pipe'
import { HttpResponse } from '@angular/common/http'
import { downloadAsExcel } from '../../../shared/functions'
import { ZeroDatePipePipe } from '../../../pipes/zero-date.pipe'
import { CaseFailureResponse, ReportTopic } from '../../../shared/interfaces'
import { ReportingService } from '../../../services/reporting.service'

@Component({
   selector: 'app-failure-mode-report',
   standalone: true,
   imports: [CommonModule, RouterLink, BooleanPipePipe, ZeroDatePipePipe],
   templateUrl: './failure-mode-report.component.html',
})
export class FailureModeReportComponent implements OnInit {
   constructor(private reportingService: ReportingService) {}

   caseFailures: CaseFailureResponse[] = []
   ngOnInit() {
      this.reportingService.getManyFailureModes().subscribe({
         next: (value) => (this.caseFailures = value),
      })
   }

   download() {
      this.reportingService
         .downloadReportAsExcel(ReportTopic.FailureMode)
         .subscribe({
            next: (value) => {
               if (value instanceof HttpResponse) {
                  downloadAsExcel(value, 'FailureModeReport')
               }
            },
         })
   }
}
