import { bootstrapApplication } from '@angular/platform-browser'
import { AppComponent } from './app/app.component'
import { provideRouter, Routes } from '@angular/router'
import { HomeComponent } from './app/pages/home/home.component'
import { LoginComponent } from './app/pages/login/login.component'
import { CaseReportComponent } from './app/pages/reports/case-list/case-report.component'
import { CaseEntryFormComponent } from './app/pages/case-entry/case-entry-form.component'
import { CaseEditorPageComponent } from './app/pages/case-editor/case-editor-page.component'
import { SearchComponent } from './app/pages/search/search.component'
import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { provideAnimations } from '@angular/platform-browser/animations'
import { ConferenceScheduleComponent } from './app/pages/conferences/schedule/conference-schedule.component'
import { AdministrationPageComponent } from './app/pages/administration/administration-page.component'
import { authInterceptor } from './app/interceptors/auth.interceptor'
import { ActionItemReportComponent } from './app/pages/reports/action-item-report/action-item-report.component'
import { AdverseEventReportComponent } from './app/pages/reports/adverse-event-report/adverse-event-report.component'
import { OutcomeReportComponent } from './app/pages/reports/outcome-report/outcome-report.component'
import { FailureModeReportComponent } from './app/pages/reports/failure-mode-report/failure-mode-report.component'
import { ConferenceFormComponent } from './app/pages/conferences/form/conference-form.component'
import { httpErrorInterceptor } from './app/interceptors/http-error.interceptor'
import { UserActivationComponent } from './app/pages/user-activation/user-activation.component'
import { authGuard } from './app/guards/auth.guard'
import { NotFoundComponent } from './app/pages/not-found/not-found.component'
import { SectionUserSecurityFormComponent } from './app/pages/administration/admin-tab-security/section-user-security/section-user-security-form.component'
import { AdminTabSectionsComponent } from './app/pages/administration/admin-tab-sections/admin-tab-sections.component'
import { AdminTabSecurityComponent } from './app/pages/administration/admin-tab-security/admin-tab-security.component'
import { AdminTabRolesComponent } from './app/pages/administration/admin-tab-roles/admin-tab-roles.component'
import { AdminTabUsersComponent } from './app/pages/administration/admin-tab-users/admin-tab-users.component'
import { AdminTabDropdownsComponent } from './app/pages/administration/admin-tab-dropdowns/admin-tab-dropdowns.component'
import { AdminTabComplicationsComponent } from './app/pages/administration/admin-tab-complications/admin-tab-complications.component'
import { AdminTabFailureModeTaxonomyComponent } from './app/pages/administration/admin-tab-failure-mode-taxonomy/admin-tab-failure-mode-taxonomy.component'
import { AdminTabOutcomesComponent } from './app/pages/administration/admin-tab-outcomes/admin-tab-outcomes.component'
import { AdminTabSubscriptionComponent } from './app/pages/administration/admin-tab-subscription/admin-tab-subscription.component'
import { DebugCustomControls } from './app/pages/debug/debug-components/debug-custom-controls.component'
import { DropdownConfigCategorizationComponent } from './app/pages/administration/admin-tab-dropdowns/dropdown-categorization/dropdown-config-categorization/dropdown-config-categorization.component'
import { DropdownConfigClinicalStatusComponent } from './app/pages/administration/admin-tab-dropdowns/dropdown-clinical-status/dropdown-config-clinical-status/dropdown-config-clinical-status.component'
import { DropdownConfigPreventabilityComponent } from './app/pages/administration/admin-tab-dropdowns/dropdown-preventability/dropdown-config-preventability/dropdown-config-preventability.component'
import { AdminTabAuditComponent } from './app/pages/administration/admin-tab-audit/admin-tab-audit/admin-tab-audit.component'

const routes: Routes = [
   {
      path: 'login',
      component: LoginComponent,
      title: 'Monarch Login',
   },
   {
      path: 'activateAccount',
      component: UserActivationComponent,
      title: 'Activate Your Account',
   },
   {
      path: 'home',
      component: HomeComponent,
      title: 'Monarch Home',
      canActivate: [authGuard],
   },
   {
      path: 'case',
      canActivateChild: [authGuard],
      children: [
         {
            path: 'new',
            component: CaseEntryFormComponent,
            title: 'New Case',
         },
         {
            path: `:id`,
            component: CaseEditorPageComponent,
            title: 'Case Editor',
         },
      ],
   },
   {
      path: 'search',
      component: SearchComponent,
      canActivate: [authGuard],
      title: 'Search Results',
   },
   {
      path: 'reports',
      canActivateChild: [authGuard],
      children: [
         {
            path: 'cases',
            component: CaseReportComponent,
            title: 'Case List',
            canActivate: [authGuard],
         },
         {
            path: 'action-items',
            component: ActionItemReportComponent,
            canActivate: [authGuard],
            title: 'Action Items',
         },
         {
            path: 'adverse-events',
            component: AdverseEventReportComponent,
            canActivate: [authGuard],
            title: 'Adverse Events',
         },
         {
            path: 'outcomes',
            component: OutcomeReportComponent,
            canActivate: [authGuard],
            title: 'Outcomes',
         },
         {
            path: 'failure-modes',
            component: FailureModeReportComponent,
            canActivate: [authGuard],
            title: 'Failure Modes',
         },
      ],
   },
   {
      path: 'conferences',
      canActivate: [authGuard],
      title: 'Conferences',
      children: [
         {
            path: 'schedule',
            component: ConferenceScheduleComponent,
            title: 'Conference Schedule',
         },
         {
            path: 'new',
            component: ConferenceFormComponent,
            title: 'New Conference',
         },
      ],
   },
   {
      path: 'administration',
      component: AdministrationPageComponent,
      canActivate: [authGuard],
      canActivateChild: [authGuard],
      title: 'Administration',
      children: [
         {
            path: 'sections',
            component: AdminTabSectionsComponent,
         },
         {
            path: 'roles',
            component: AdminTabRolesComponent,
         },
         {
            path: 'users',
            component: AdminTabUsersComponent,
         },
         {
            path: 'section-membership',
            component: AdminTabSecurityComponent,
            children: [
               {
                  path: 'edit',
                  component: SectionUserSecurityFormComponent,
                  title: 'Edit Section Membership',
               },
            ],
         },
         {
            path: 'dropdowns',
            component: AdminTabDropdownsComponent,
            children: [
               {
                  path: 'level-of-preventability',
                  component: DropdownConfigPreventabilityComponent,
                  title: 'Level of Preventability',
               },
               {
                  path: 'change-in-clinical-status',
                  component: DropdownConfigClinicalStatusComponent,
                  title: 'Change in Clinical Status',
               },
               {
                  path: 'categorization',
                  component: DropdownConfigCategorizationComponent,
                  title: 'Categorization',
               },
            ],
         },
         {
            path: 'complications',
            component: AdminTabComplicationsComponent,
         },
         {
            path: 'outcomes',
            component: AdminTabOutcomesComponent,
         },
         {
            path: 'failure-modes',
            component: AdminTabFailureModeTaxonomyComponent,
         },
         {
            path: 'audit-trail',
            component: AdminTabAuditComponent,
         },
         {
            path: 'license',
            component: AdminTabSubscriptionComponent,
         },
      ],
   },
   {
      path: 'debug',
      component: DebugCustomControls,
   },
   {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full', // Must pathMatch full when checking an empty route
   },
   {
      path: '**', // Catch everything else
      component: NotFoundComponent,
      title: '404 Not Found',
   },
]

bootstrapApplication(AppComponent, {
   providers: [
      provideRouter(routes),
      provideHttpClient(
         withInterceptors([httpErrorInterceptor, authInterceptor])
      ),
      provideAnimations(),
   ],
}).catch((error) => console.log(error))
