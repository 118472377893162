<div>
   @if (isProcessing()) {
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
   }

   <table class="min-w-full border border-spacing-0">
      <thead>
         <tr
            class="sticky top-0 z-10 divide-x divide-y divide-gray-200 border-b border-gray-300 bg-gray-100 text-sm font-semibold text-gray-900 bg-opacity-75 backdrop-blur"
         >
            @for (column of columnConfiguration; track column.key) {
               @if (column.isVisible) {
                  <th
                     scope="col"
                     class="py-1.5 px-3 text-left whitespace-nowrap"
                     [ngClass]="{
                        'text-left': column.headerAlignment === 'left',
                        'text-right': column.headerAlignment === 'right',
                        'text-center': column.headerAlignment === 'center'
                     }"
                  >
                     {{ column.displayValue }}
                  </th>
               }
            }
         </tr>
      </thead>

      <tbody class="divide-y divide-gray-200">
         @for (row of tableData; let rowIndex = $index; track rowIndex) {
            <tr
               class="divide-x divide-gray-200 hover:bg-gray-50"
               (click)="selectRow(row, rowIndex)"
            >
               @for (column of columnConfiguration; track $index) {
                  @if (column.isVisible) {
                     @if (column.key === 'selectionColumn') {
                        <td
                           class="whitespace-nowrap border-b border-gray-200 px-3 py-3 text-sm text-gray-800 text-center"
                        >
                           <input
                              type="radio"
                              [checked]="rowIndex === selectedRowIndex"
                              class="h-5 w-5 border-gray-300 border-dashed text-indigo-600 focus:ring-indigo-600"
                           />
                        </td>
                     } @else {
                        @switch (column.dataType) {
                           @case ('list') {
                              <td
                                 class="whitespace-nowrap border- b border-gray-200 px-3 py-3 text-sm text-gray-800 text-left"
                              >
                                 <ul class="space-y-1">
                                    @for (
                                       item of row[column.key];
                                       track $index
                                    ) {
                                       <li>
                                          <app-label
                                             text="{{ item[column.listPath!] }}"
                                          ></app-label>
                                       </li>
                                    }
                                 </ul>
                              </td>
                           }
                           @case ('internalURL') {
                              <td
                                 class="whitespace-nowrap border-b border-gray-200 px-3 py-3 text-left text-sm text-gray-800"
                                 (click)="
                                    column.routeConfig
                                       ? navigateTo(column.routeConfig, row)
                                       : null
                                 "
                              >
                                 {{ row[column.key] }}
                              </td>
                           }
                           @default {
                              <td
                                 class="whitespace-nowrap border-b border-gray-200 px-3 py-3 text-left text-sm text-gray-800"
                                 [ngClass]="{
                                    'text-left':
                                       column.cellAlignment === 'left',
                                    'text-right':
                                       column.cellAlignment === 'right',
                                    'text-center':
                                       column.cellAlignment === 'center'
                                 }"
                              >
                                 <!--{{ row[column.key] }}-->
                                 {{ getNestedValue(row, column.key) }}
                              </td>
                           }
                        }
                     }
                  }
               }
            </tr>
         }
      </tbody>
   </table>
</div>
