<div class="flex flex-col space-y-6">
   <div>
      <p class="text-2xl">Controls</p>
      <hr class="mb-3" />
      <div class="flex flex-row space-x-2">
         <app-select-list label="Select List" [options]="[]"></app-select-list>
         <app-input-text name="inputText" label="Text Input"></app-input-text>
         <app-input-number
            name="inputNumber"
            label="Numeric Input"
         ></app-input-number>
         <app-input-date name="inputDate" label="Date Input"></app-input-date>
         <app-checkbox-group
            [options]="[
               { value: 1, label: 'Option 1' },
               { value: 2, label: 'Option Two' }
            ]"
         ></app-checkbox-group>
      </div>
   </div>

   <div>
      <p class="text-2xl">Typography</p>
      <hr class="mb-3" />
      <div class="flex flex-col space-y-2">
         <p class="monarch-text-header">Page Header</p>
         <p class="monarch-text-subheader">Sub-Header</p>
         <p class="monarch-text">Standard Text</p>
         <p class="monarch-text-accent">Accented Text</p>
         <p class="monarch-text-hyperlink-internal">Internal Links</p>
         <p class="monarch-text-footnote">Footnote</p>
      </div>
   </div>

   <div>
      <p class="text-2xl">Buttons</p>
      <hr class="mb-3" />
      <div class="flex flex-row space-x-2">
         <app-button label="Call to Action"></app-button>
         <app-button label="Standard" buttonType="secondary"></app-button>
         <app-button-danger label="Dangerous"></app-button-danger>
      </div>
   </div>

   <div>
      <p class="text-2xl">Colors</p>
      <hr class="mb-3" />
      <div class="flex flex-col space-y-6">
         <div class="flex flex-row space-x-6">
            <div class="w-24 h-24 bg-gray-100 text-black content-center">
               gray-100
            </div>
            <div class="w-24 h-24 bg-gray-400 text-white content-center">
               gray-400
            </div>
            <div class="w-24 h-24 bg-gray-900 text-white content-center">
               gray-900
            </div>
         </div>
         <div class="flex flex-row space-x-6">
            <div class="w-24 h-24 bg-orange-400 text-white content-center">
               orange-400
            </div>
         </div>
         <div class="flex flex-row space-x-6">
            <div class="w-24 h-24 bg-indigo-600 text-white content-center">
               indigo-600
            </div>
            <div class="w-24 h-24 bg-indigo-900 text-white content-center">
               indigo-900
            </div>
         </div>
      </div>
   </div>
</div>
