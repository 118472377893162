import { inject, Injectable } from '@angular/core'
import {
   AccountActivation,
   ClinicalSection,
   TokenBundle,
   User,
   UserCredentials,
} from '../shared/interfaces'
import { LoggingService } from './logging.service'
import { Router } from '@angular/router'
import { Observable, timeout } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { ConfigurationService } from './configuration.service'
import { NotificationService, NotificationType } from './notification.service'

@Injectable({
   providedIn: 'root',
})
// UserService describes the current user.
export class UserService {
   endpoint = inject(ConfigurationService).getAuthorizationEndpoint()
   apiRoot = inject(ConfigurationService).getEndpoint()

   constructor(
      private logger: LoggingService,
      private router: Router,
      private http: HttpClient,
      private notificationService: NotificationService
   ) {}

   user: User | undefined

   getAuthorizationToken(
      credentials: UserCredentials
   ): Observable<TokenBundle> {
      const TIMEOUT_MS = 5000
      return this.http
         .post<TokenBundle>(
            `${this.endpoint}/auth/token`,
            JSON.stringify(credentials)
         )
         .pipe(timeout(TIMEOUT_MS))
   }

   login(credentials: UserCredentials) {
      this.getAuthorizationToken(credentials).subscribe({
         next: (token) => {
            this.user = token.authenticatedUser
            localStorage.setItem('monarchAccessToken', token.accessToken)
            localStorage.setItem(
               'monarchAccessTokenExpiry',
               token.accessTokenExpiry.toString()
            )
            localStorage.setItem('userProfile', JSON.stringify(this.user))
            localStorage.setItem('monarchRefreshToken', token.refreshToken)
            localStorage.setItem(
               'monarchRefreshTokenExpiry',
               token.refreshTokenExpiry.toString()
            )
            this.router.navigate(['home']) // TODO: prior page
         },
         // TODO: Handle error messages like this for every API: https://gitlab.com/monarch-systems/morbidity-and-mortality/-/issues/313
         error: (err) => {
            if (err.name === 'TimeoutError') {
               this.notificationService.new(
                  NotificationType.Error,
                  'Request timed out'
               )
            } else if (err.error.error) {
               // API response body will be {"error": "message"}
               this.notificationService.new(
                  NotificationType.Error,
                  err.error.error
               )
            } else if (err.status === 0) {
               this.notificationService.new(
                  NotificationType.Error,
                  'Unable to connect to Monarch Server. Please check your internet connection. If the issue persists, contact your Monarch Administrator.'
               )
            } else {
               this.notificationService.new(
                  NotificationType.Error,
                  'An unexpected error occurred: ' + err
               )
            }
         },
      })
   }

   activateAccount(input: AccountActivation): Observable<any> {
      return this.http.post<AccountActivation>(
         `${this.endpoint}/auth/activateAccount`,
         JSON.stringify(input)
      )
   }

   logout() {
      // TODO: management of local storage should be relegated to storageService
      localStorage.removeItem('monarchAccessToken')
      localStorage.removeItem('monarchAccessTokenExpiry')
      localStorage.removeItem('userProfile')
      localStorage.removeItem('monarchRefreshToken')
      localStorage.removeItem('monarchRefreshTokenExpiry')
      localStorage.removeItem('recentlyViewedCases')
   }

   // TODO: Should check the refresh token instead
   isLoggedIn(): boolean {
      let evaluationInstant: Date = new Date()
      let expiry: string | null = localStorage.getItem(
         'monarchAccessTokenExpiry'
      )
      // Check for Falsy value / unable to retrieve from localStorage
      if (!expiry) {
         return false
      } else {
         let expirationInstant = new Date(Date.parse(expiry))
         return expirationInstant > evaluationInstant
      }
   }

   getAccessToken(): string | null {
      return localStorage.getItem('monarchAccessToken')
   }

   getRefreshToken(): string | null {
      return localStorage.getItem('monarchRefreshToken')
   }

   getAuthenticatedUser(): User {
      return JSON.parse(localStorage.getItem('userProfile')!)
   }

   getAccessibleSections(): ClinicalSection[] {
      let s = this.getAuthenticatedUser().accessibleSections
      if (s === null) {
         return []
      } else {
         return s
      }
   }

   createUser(
      email: string,
      firstName: string,
      lastName: string
   ): Observable<User> {
      console.log('creating user')
      return this.http.post<User>(
         `${this.apiRoot}/user`,
         JSON.stringify({
            email: email,
            firstName: firstName,
            lastName: lastName,
         })
      )
   }
}
