<div
   class="relative w-12 h-6 flex items-center bg-gray-300 rounded-full cursor-pointer transition-colors duration-300"
   [class.bg-green-500]="value"
   (click)="toggle()"
>
   <div
      class="absolute w-5 h-5 bg-white rounded-full shadow transform transition-transform duration-300"
      [style.transform]="value ? 'translateX(1.5rem)' : 'translateX(0)'"
   ></div>
</div>
<div class="mt-1 text-sm">
   @if (value) {
      <span class="text-green-600">{{ labelOn }}</span>
   } @else {
      <span class="text-gray-600">{{ labelOff }}</span>
   }
</div>
