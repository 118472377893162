import {
   Component,
   EventEmitter,
   Input,
   model,
   OnInit,
   Output,
   signal,
} from '@angular/core'
import { ModalComponent } from '../../../../shared/components/modal/modal.component'
import {
   SelectListComponent,
   SelectListOption,
} from '../../../../shared/components/controls/select-list/select-list.component'
import { AdminService } from '../../../../services/admin.service'
import {
   ClinicalSection,
   Role,
   SectionMember,
   User,
} from '../../../../shared/interfaces'
import { forkJoin, tap } from 'rxjs'
import {
   FormBuilder,
   FormsModule,
   ReactiveFormsModule,
   Validators,
} from '@angular/forms'
import { ButtonComponent } from '../../../../shared/components/button/button.component'
import { ActivatedRoute } from '@angular/router'
import {
   CheckboxGroupComponent,
   CheckboxOption,
} from '../../../../shared/components/controls/checkbox-group/checkbox-group.component'

// TODO: When userID and sectionID is selected, fetch currently active roleIDs to pre-populate checkbox-group
@Component({
   selector: 'app-section-user-security-form',
   standalone: true,
   imports: [
      ModalComponent,
      SelectListComponent,
      FormsModule,
      ReactiveFormsModule,
      ButtonComponent,
      CheckboxGroupComponent,
   ],
   templateUrl: './section-user-security-form.component.html',
})
export class SectionUserSecurityFormComponent implements OnInit {
   constructor(
      private adminService: AdminService,
      private fb: FormBuilder,
      private route: ActivatedRoute
   ) {}

   @Input() initialSectionID?: number
   @Input() initialUserID?: number
   @Output() onSubmitEmitter: EventEmitter<SectionMember> = new EventEmitter()
   formIsDisplayed = model(false)
   sections = signal<{ data: ClinicalSection[]; options: SelectListOption[] }>({
      data: [],
      options: [],
   })
   users = signal<{ data: User[]; options: SelectListOption[] }>({
      data: [],
      options: [],
   })
   roles = signal<{ data: Role[]; options: CheckboxOption[] }>({
      data: [],
      options: [],
   })

   sectionUserSecurityForm = this.fb.group({
      sectionID: [0, Validators.required],
      userID: [0, Validators.required],
      roleIDs: [[]],
   })

   ngOnInit() {
      this.route.queryParamMap
         .pipe(
            tap((params) => {
               this.initialSectionID = params.get('sectionID')
                  ? parseInt(params.get('sectionID')!, 10)
                  : undefined
               this.initialUserID = params.get('userID')
                  ? parseInt(params.get('userID')!, 10)
                  : undefined
            })
         )
         .subscribe()

      forkJoin({
         sectionList: this.adminService.getAllSections(),
         userList: this.adminService.getManyUsers(),
         roleList: this.adminService.getInstanceRoles(),
      }).subscribe(({ sectionList, userList, roleList }) => {
         this.sections.set({
            data: sectionList,
            options: sectionList.map((section: ClinicalSection) =>
               this.mapSectionToSelectList(section)
            ),
         })
         this.users.set({
            data: userList,
            options: userList.map((user: User) =>
               this.mapUserToSelectList(user)
            ),
         })
         this.roles.set({
            data: roleList.roles,
            options: roleList.roles.map((role: Role) =>
               this.mapRoleToCheckboxOption(role)
            ),
         })
      })

      this.sectionUserSecurityForm.patchValue({
         sectionID: this.initialSectionID,
         userID: this.initialUserID,
      })
   }

   mapSectionToSelectList(section: ClinicalSection): SelectListOption {
      return {
         returnValue: section.sectionID,
         displayValue: section.name,
      }
   }

   mapUserToSelectList(user: User): SelectListOption {
      return {
         returnValue: user.userID,
         displayValue: user.email,
      }
   }

   mapRoleToCheckboxOption(role: Role): CheckboxOption {
      return {
         value: role.roleID,
         label: role.roleName,
      }
   }

   onSubmit() {
      const formData = this.sectionUserSecurityForm.value
      this.adminService
         .addSectionMember(formData.sectionID!, {
            userID: formData.userID!,
            roleIDs: formData.roleIDs!,
         })
         .subscribe({
            next: (sectionMember) => {
               this.onSubmitEmitter.emit(sectionMember)
               this.formIsDisplayed.set(false)
            },
         })
   }
}
