<div class="min-w-48">
   <label
      id="listbox-label"
      class="block text-sm font-medium leading-6 text-gray-900"
      >{{ label }}</label
   >
   <div class="relative mt-1" (blur)="handleBlur()">
      <button
         type="button"
         (click)="optionsExpanded = !optionsExpanded"
         class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
      >
         <span class="inline-flex w-full truncate">
            <span class="truncate">{{ selectedOption?.displayValue }}</span>
         </span>
         <span
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
         >
            <svg
               class="h-5 w-5 text-gray-400"
               viewBox="0 0 20 20"
               fill="currentColor"
               aria-hidden="true"
            >
               <path
                  fill-rule="evenodd"
                  d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                  clip-rule="evenodd"
               />
            </svg>
         </span>
      </button>
      @if (optionsExpanded) {
         <ul
            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto divide-y divide-gray-100 rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            tabindex="-1"
            role="listbox"
            aria-labelledby="listbox-label"
         >
            @for (option of options; track $index) {
               <li
                  class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-indigo-600 hover:text-white"
                  id="listbox-option-0"
                  role="option"
                  [value]="option.returnValue"
                  (click)="handleChange(option)"
               >
                  <div class="flex">
                     <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                     <span
                        class="truncate font-normal"
                        [ngClass]="{
                           'font-semibold': isSelectedOption(option),
                           'font-normal': !isSelectedOption(option)
                        }"
                        >{{ option.displayValue }}</span
                     >
                  </div>

                  <!--Checkmark-->
                  @if (option.returnValue === selectedOption?.returnValue) {
                     <span
                        class="absolute inset-y-0 right-0 flex items-center pr-4 font-semibold"
                        [ngClass]="{
                           'text-indigo-600': isSelectedOption(option),
                           'text-white': !isSelectedOption(option)
                        }"
                     >
                        <svg
                           class="h-5 w-5"
                           viewBox="0 0 20 20"
                           fill="currentColor"
                           aria-hidden="true"
                        >
                           <path
                              fill-rule="evenodd"
                              d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                              clip-rule="evenodd"
                           />
                        </svg>
                     </span>
                  }
               </li>
            }
         </ul>
      }
   </div>
</div>
