<div class="flex flex-col justify-center mt-24">
   <div class="min-w-80">
      <!--<img src="assets/nch_logo.png" class="mx-auto w-96 pb-6" />-->
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">
         <h2 class="text-2xl text-center text-orange-400 font-semibold">
            Monarch.
         </h2>
      </div>

      <div class="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
         <form
            class="space-y-6"
            action="#"
            method="POST"
            [formGroup]="loginGroup"
         >
            <div>
               <label
                  for="email"
                  autofocus
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Email</label
               >
               <div class="mt-2">
                  <input
                     formControlName="email"
                     tabindex="1"
                     id="email"
                     name="email"
                     type="email"
                     autocomplete="email"
                     required
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
               </div>
            </div>

            <div>
               <div class="flex items-center justify-between">
                  <label
                     for="password"
                     class="block text-sm font-medium leading-6 text-gray-900"
                     >Password</label
                  >
                  <div class="text-sm">
                     <a
                        href="#"
                        class="font-semibold text-indigo-600 hover:text-indigo-500"
                        >Forgot password?</a
                     >
                  </div>
               </div>
               <div class="mt-2">
                  <input
                     formControlName="password"
                     tabindex="2 "
                     id="password"
                     (keyup.enter)="login()"
                     name="password"
                     type="password"
                     autocomplete="current-password"
                     required
                     class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
               </div>
            </div>

            <div>
               <button
                  (click)="login()"
                  tabindex="3"
                  type="submit"
                  class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
               >
                  Sign in
               </button>
            </div>
         </form>

         <p class="mt-10 text-center text-sm text-gray-500">
            Don't have an account?
            <a
               href="#"
               class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
               >Request access</a
            >
         </p>

         <div
            *ngIf="displayedErrorMessage"
            class="rounded-md bg-red-50 p-4 mt-4"
         >
            <div class="flex justify-center">
               <div class="flex-shrink-0">
                  <svg
                     class="h-5 w-5 text-red-400"
                     viewBox="0 0 20 20"
                     fill="currentColor"
                     aria-hidden="true"
                  >
                     <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                        clip-rule="evenodd"
                     />
                  </svg>
               </div>
               <div class="ml-3">
                  <h3 class="text-sm font-medium text-red-800">
                     {{ displayedErrorMessage }}
                  </h3>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="fixed bottom-6 left-3">
      <div>
         <p class="monarch-text-subheader pb-2">Release Notes</p>
         <ul class="space-y-2 list-disc list-inside">
            @for (note of releaseNote; track $index) {
               <li class="monarch-text">
                  <span class="font-semibold">{{ note.date }}</span> -
                  {{ note.desc }}
               </li>
            }
         </ul>
      </div>
   </div>
</div>
