import { formMode, formAction } from './enums'

export interface ActionItem {
   actionItemID: number
   caseID: number
   title: string
   status: string
   statusID: number
   closeDate: string
   dueDate: Date
   isClosed: boolean
   isOverdue: boolean
   createdAt: string
   createdByID: number
   createdBy: string
   isAssigned: boolean
   assignees: User[]
   assigneesDisplay: string
   isDeleted: boolean
   deletedAt: Date
   deletedByID: number
   deletedBy: string
   linkedCase: MonarchCase
}

export interface AdverseEvent {
   id: number
   caseID: number
   complicationID: number
   complicationName: string
   complicationCategoryID: number
   complicationCategory: string
   complicationDescription: string
   note: string
   createdAt: Date
   createdByID: number
   createdBy: string
   isDeleted: boolean
   deletedAt: Date
   deletedByID: number
   deletedBy: string
}

// FIXME: Near duplicate of above
export interface AdverseEventAPIResponse {
   id: number
   caseID: number
   complicationID: number
   complicationName: string
   complicationCategory: string
   complicationDescription: string
   note: string
   createdAt: Date
   createdByUserID: number
   createdByUserName: string
   isDeleted: boolean
   deletedAt: Date
   deletedByUserID: number
   deletedByUserName: string
   linkedCase: MonarchCase
}

export interface MonarchCase {
   caseID: number
   sectionID: number
   sectionName: string
   conferenceID: number
   conferenceDate: Date
   patientInternalID: number
   patientFhirID: string
   patientMRN: string
   patientName: string
   patientDOB: Date
   encounterInternalID: number
   encounterFhirID: string
   encounterCSN: number
   encounterStart: Date
   encounterEnd: Date
   isOpen: boolean
   canClose: boolean
   summary: string
   diagnosis: string
   discussion: string
   categorizationID: number
   categorization: string
   levelOfPreventabilityID: number
   levelOfPreventability: string
   changeInClinicalStatusID: number
   changeInClinicalStatus: string
   createdAt: Date
   createdBy: string
}

export interface CaseLinkedEncounter {
   internalID: string
   fhirID: string
   encounterIdentifier: number
   startedAt: Date
   endedAt: Date
   isPrimary: boolean
   note: string
   type: string
   class: string
   status: string
}

export interface CaseLinkedProcedure {
   internalID: number
   fhirID: string
   procedureName: string
   performedAt: Date
   cptCode: string
   isPrimary: boolean
   note: string
}

export interface CaseLinkedPractitioner {
   internalID: number
   fhirID: string
   fullName: string
   note: string
}

export interface CaseFailure {
   id: number
   isLinkedWithComplication: boolean
   linkedComplicationID: number
   failureType: string
   failureModeID: number
   failureModeName: string
   failureModePath: string
   note: string
   createdAt: Date
   createdByID: number
   createdBy: string
   isDeleted: boolean
}

// FIXME: Near duplicate of above
export interface CaseFailureResponse {
   id: number
   caseID: number
   isLinkedWithComplication: boolean
   linkedComplicationID: number
   failureType: string
   failureModeID: number
   failureModeName: string
   failureModePath: string
   note: string
   createdAt: Date
   createdByID: number
   createdBy: string
   isDeleted: boolean
   deletedAt: Date
   deletedByUserID: number
   deletedByUserName: string
   linkedCase: MonarchCase
}

export interface Conference {
   conferenceID: number
   sectionID: number
   startTime: Date
   caseCount: number
   caseList: MonarchCase[]
}

export interface Document {
   documentID: number
   caseID: number
   documentName: string
   documentGUID: string
   mimeType: string
   error?: string
   fileSizeHumanReadable: string
   uploadedAt: Date
   uploadedByUserID: number
   uploadedBy: string
   isDeleted: boolean
   deletedAt: Date
   deletedByID: number
   deletedBy: string
}

export interface ClinicalSection {
   sectionID: number
   name: string
   enabledRoles: Role[]
}

export interface SearchResultPatient {
   FHIRID: string
   MRN: string
   Gender: string
   DOB: string
   FirstName: string
   LastName: string
   FullName: String
}

export interface SearchResultEncounter {
   FHIRID: string
   EncounterCSN: string
   Start: string
   End: string
   Type: string
}

export interface SearchResultProcedure {
   fhirID: string
   status: string
   cptCode: string
   name: string
   performedAt: Date
   performedBy: string //FIXME: API doesn't return this
   reason: string
}

export interface UserCredentials {
   email: string
   password: string
}
export interface TokenBundle {
   accessToken: string
   accessTokenExpiry: Date
   refreshToken: string
   refreshTokenExpiry: Date
   authenticatedUser: User
}

export interface User {
   userID: number
   username: string
   email: string
   name: string
   firstName: string
   lastName: string
   state: string
   profilePicPath: string
   isInstanceAdmin: boolean
   createdAt: string
   accessibleSections: ClinicalSection[]
}

export interface CaseAdverseEventInput {
   caseID: number
   complicationID: number
   note: string
}

export interface Complication {
   id: number
   name: string
   description: string
   categoryID: number
   category: string
}

export interface Outcome {
   id: number
   caseID: number
   outcomeID: number
   outcomeName: string
   outcomeDescription: string
   note: string
   createdAt: Date
   createdByID: number
   createdBy: string
   isDeleted: boolean
   deletedAt: Date
   deletedByID: number
   deletedBy: string
   linkedCase: MonarchCase
}

export interface CreateOutcome {
   caseID: number
   outcomeID: number
   note: string
}

export interface FormEvent<T> {
   formAction: formAction
   formMode: formMode
   data: T
}

export interface MonarchCaseTab {
   caseID: number
   allowEditing: boolean
}

export interface CreateActionItem {
   caseID: string
   title: string
   statusID: number | null
   dueDate: string | null
   closeDate: string | null
   assigneeIDs: number[] | null
}

export interface SearchResult {
   caseID: number
   patientName: string
   section: string
   MRN: string
   fragments: string
}

export interface Capability {
   id: number
   name: string
   description: string
   abbreviation: string
}

export interface CreateFailure {
   failureModeID: number
   note: string
}

export interface CreateCaseInputs {
   sectionID: number
   patientFhirID: string
   encounterFhirID: string
   procedureFhirID: string | null
}

export enum ReportTopic {
   ActionItem = 'actionItem',
   Outcome = 'outcome',
   FailureMode = 'failureMode',
   AdverseEvent = 'adverseEvent',
   Case = 'case',
}

export interface FailureModeTaxonomyNode {
   failureModeID: number
   name: string
   path: string
   isRootNode: boolean
   parentFailureModeID: number
   rootName: string
   depth: number
   isExpandable: boolean
   isExpanded: boolean
}

export type FailureModeTaxonomy = FailureModeTaxonomyNode[]

export interface InstanceRoleConfiguration {
   roles: Role[]
}

export interface Role {
   roleID: number
   roleName: string
   securityPoints: SecurityPoint[]
}

export interface SecurityPoint {
   securityPointID: number
   securityPointName: string
}

export interface AccountActivation {
   activationToken: string
   email: string
   newPassword: string
}

export interface InteractiveTableColumn {
   key: string
   displayValue: string
   sortIndex: number
   isVisible: boolean
   dataType: // TODO: 'dataType' is a mixture or format and behavior. At some point split this into how the data is displayed versus how it's used
   | 'string'
      | 'number'
      | 'date'
      | 'datetime'
      | 'externalURL'
      | 'internalURL'
      | 'list'
      | 'eventEmitter'
   routeConfig?: {
      path: string
      params: { [key: string]: string | number }
      displayValue: string
   }
   listPath?: string
   headerAlignment?: 'left' | 'right' | 'center'
   cellAlignment?: 'left' | 'right' | 'center'
}

export interface SectionUserPermission {
   sectionID: number
   sectionName: string
   userID: number
   firstName: string
   lastName: string
   email: string
   roles: string[]
   editLink: string
}

export interface SectionMember {
   section: ClinicalSection
   user: User
   roles: Role[]
   securityPoints: SecurityPoint[]
}

export interface Practitioner {
   fhirID: string
   fullName: string
}

export interface CaseAuditEvent {
   event: {
      ID: number
      caseID: number
      type: string
      template: string
      interpolatedDescription: string
      SVGPath: string
      timestamp: Date
   }
   user: User
   resource: {
      ID: number
      snapshotPre: object
      snapshotPost: object
   }
}

export interface PlatformAuditEvent {
   event: {
      ID: number
      type: string
   }
   user: User
}

export interface RegionDisplaySelectorTab {
   key: string
   displayValue: string
   isSelected: boolean
   routerStrategy?: 'relative' | 'absolute'
   routerLink?: string
}
