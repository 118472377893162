import { Component, inject, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { InputTextComponent } from '../../../shared/components/controls/input-text/input-text.component'
import { ButtonComponent } from '../../../shared/components/button/button.component'
import {
   FormControl,
   FormGroup,
   ReactiveFormsModule,
   Validators,
} from '@angular/forms'
import { ConferenceService } from '../../../services/conference.service'
import {
   NotificationService,
   NotificationType,
} from '../../../services/notification.service'
import { Router } from '@angular/router'
import { InputDateComponent } from '../../../shared/components/controls/input-date/input-date.component'
import { dateToLocalizedMidnight } from '../../../shared/functions'
import { SelectListComponent } from '../../../shared/components/controls/select-list/select-list.component'
import { UserService } from '../../../services/user.service'
import { ClinicalSection } from '../../../shared/interfaces'

@Component({
   selector: 'app-conference-form',
   standalone: true,
   imports: [
      CommonModule,
      InputTextComponent,
      ButtonComponent,
      ReactiveFormsModule,
      InputDateComponent,
      SelectListComponent,
   ],
   templateUrl: './conference-form.component.html',
})
export class ConferenceFormComponent implements OnInit {
   conferenceService: ConferenceService = inject(ConferenceService)
   notificationService: NotificationService = inject(NotificationService)
   router: Router = inject(Router)
   userService: UserService = inject(UserService)
   selectableSections: ClinicalSection[] = []
   conferenceForm = new FormGroup({
      sectionID: new FormControl(null, [Validators.required]),
      startTime: new FormControl(null, [Validators.required]),
   })

   ngOnInit() {
      this.selectableSections = this.userService
         .getAccessibleSections()
         .slice(0)
   }

   create() {
      let conferenceDate = dateToLocalizedMidnight(
         this.conferenceForm.controls.startTime.getRawValue()!
      )

      this.conferenceService
         .createConference(
            +this.conferenceForm.controls.sectionID.getRawValue()!,
            conferenceDate
         )
         .subscribe({
            next: (value) => {
               this.notificationService.new(
                  NotificationType.OK,
                  'Successfully created'
               )
               this.router.navigate(['conferences', 'new'])
            },
            error: (err) =>
               this.notificationService.new(
                  NotificationType.Error,
                  'Something went wrong'
               ),
         })
   }
}
