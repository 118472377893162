<div>
   <label class="monarch-text text-sm font-medium">{{ label }}</label>
   <div class="mt-1">
      @for (option of options; track $index) {
         <div class="mb-2 flex items-center space-x-2 cursor-pointer">
            <label class="flex items-center space-x-2">
               <input
                  type="checkbox"
                  [checked]="isChecked(option.value)"
                  (change)="toggleSelection(option.value)"
                  class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
               />
               <span class="monarch-text text-sm">{{ option.label }}</span>
            </label>
         </div>
      }
   </div>
</div>
