import {
   Component,
   effect,
   Input,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { CaseManagementService } from '../../../services/case-management.service'
import { AdverseEvent, MonarchCaseTab } from '../../../shared/interfaces'
import {
   SimpleList,
   SimpleListComponent,
   SimpleListItem,
} from '../../../shared/components/simple-list/simple-list.component'
import { AdverseEventFormComponent } from './adverse-event-form.component'
import {
   FloatingOptionsMenuComponent,
   optionsMenu,
} from '../../../shared/components/floating-options-menu/floating-options-menu.component'

@Component({
   selector: 'app-tab-adverse-events',
   standalone: true,
   imports: [
      CommonModule,
      AdverseEventFormComponent,
      FloatingOptionsMenuComponent,
      SimpleListComponent,
   ],
   templateUrl: './tab-case-adverse-events.component.html',
})
export class TabCaseAdverseEvents implements OnInit, MonarchCaseTab {
   @Input({ required: true }) caseID!: number
   @Input({ required: true }) allowEditing: boolean = false
   $caseAdverseEvents: WritableSignal<AdverseEvent[]> = signal([])
   adverseEventOptions = adverseEventOptions
   adverseEventSortOptionsDisplayed: boolean = false

   constructor(private caseManagementService: CaseManagementService) {
      effect(() => {
         this.adverseEventDisplayList = this.$caseAdverseEvents().map(
            (x: AdverseEvent) => this.mapAdverseEventToListItem(x)
         )
      })
   }
   ngOnInit() {
      this.caseManagementService
         .getRelatedAdverseEvents(this.caseID)
         .subscribe({
            next: (value) =>
               this.$caseAdverseEvents.set(value.filter((x) => !x.isDeleted)),
         })
   }

   // MARK: Adverse Events
   $adverseEventFormIsDisplayed: WritableSignal<boolean> = signal(false)
   adverseEventDisplayList: SimpleList = []
   selectedCaseAdverseEventID: number = 0
   mapAdverseEventToListItem(ae: AdverseEvent): SimpleListItem {
      let createDate = new Date(ae.createdAt)

      return {
         id: ae.id,
         title: ae.complicationName,
         description: ae.note,
         propertyPairs: [
            {
               key: 'CREATEDATE',
               display: 'Created',
               value: createDate.toLocaleString(),
            },
            {
               key: 'CREATEDBY',
               display: 'By',
               value: ae.createdBy,
            },
         ],
      }
   }
}

let adverseEventOptions: optionsMenu = [
   [
      { key: 'NAME', displayValue: 'Adverse Event Name', isSelected: true },
      {
         key: 'CREATED_AT',
         displayValue: 'Creation Date',
         isSelected: false,
      },
      { key: 'UPDATED_AT', displayValue: 'Update Date', isSelected: false },
      {
         key: 'CREATED_BY',
         displayValue: 'Creation User',
         isSelected: false,
      },
      { key: 'UPDATED_BY', displayValue: 'Update User', isSelected: false },
   ],
   [
      { key: 'ASC', displayValue: 'Ascending', isSelected: false },
      { key: 'DESC', displayValue: 'Descending', isSelected: true },
   ],
]
