import { AfterContentInit, Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CaseManagementService } from '../../../services/case-management.service'
import { OnInit } from '@angular/core'
import {
   MonarchCase,
   ClinicalSection,
   Conference,
} from '../../../shared/interfaces'
import { FormsModule } from '@angular/forms'
import { CaseListComponent } from '../../../views/case-list/case-list.component'
import { ButtonComponent } from '../../../shared/components/button/button.component'
import { UserService } from '../../../services/user.service'
import { concat, mergeMap, tap } from 'rxjs'
import { ConferenceService } from '../../../services/conference.service'
import { NoDataFoundComponent } from '../../../shared/components/empty-state/no-data-found/no-data-found.component'
import { StorageService } from '../../../services/storage.service'
import { Router, RouterModule } from '@angular/router'
@Component({
   selector: 'app-conference-schedule',
   standalone: true,
   imports: [
      CommonModule,
      FormsModule,
      CaseListComponent,
      ButtonComponent,
      NoDataFoundComponent,
      RouterModule,
   ],
   templateUrl: './conference-schedule.component.html',
})
export class ConferenceScheduleComponent implements OnInit {
   constructor(
      private userService: UserService,
      protected storageService: StorageService,
      private conferenceService: ConferenceService,
      private router: Router
   ) {}
   selectedSectionID: number | undefined
   selectedConferenceID: number | undefined
   conferenceList: Conference[] = []
   conferenceSchedule: MonarchCase[] = []
   accessibleSections: ClinicalSection[] = []

   ngOnInit() {
      this.accessibleSections = this.userService.getAccessibleSections()
      this.selectedSectionID = this.accessibleSections[0].sectionID
      this.conferenceService
         .getConferencesForSection(this.selectedSectionID)
         .pipe(
            tap((conferences) => {
               this.conferenceList = conferences
               this.selectedConferenceID = this.conferenceList[0].conferenceID
            }),
            mergeMap((conferences) =>
               this.conferenceService.getConferenceScedule(
                  conferences[0].conferenceID
               )
            )
         )
         .subscribe({
            next: (value) => (this.conferenceSchedule = value.caseList),
         })
   }

   debug() {
      console.log(this.selectedConferenceID)
   }

   navigateToNewConferenceForm() {
      this.router.navigate(['conferences', 'new'])
   }

   getCasesForSelectedConference() {
      this.conferenceService
         .getConferenceScedule(this.selectedConferenceID!)
         .subscribe({
            next: (value) => (this.conferenceSchedule = value.caseList),
         })
   }
}
