import { Component, forwardRef, Input } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { NgClass } from '@angular/common'

@Component({
   selector: 'app-select-list',
   standalone: true,
   imports: [NgClass],
   templateUrl: './select-list.component.html',
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => SelectListComponent),
         multi: true,
      },
   ],
})
export class SelectListComponent implements ControlValueAccessor {
   @Input({ required: true }) label: string = ''
   @Input({ required: true }) options: SelectListOption[] = []
   selectedOption: SelectListOption | undefined
   value: any
   optionsExpanded: boolean = false

   // The method set when registerOnChange is called
   onChange: any = () => {}
   // The method set when registerOnTouched is called
   onTouched: any = () => {}

   // Write a new value to the element
   writeValue(value: any): void {
      this.value = value
      this.selectedOption = this.options.find(
         (opt) => opt.returnValue === value
      )
   }

   // Save a callback function that should be called when the control's value changes in the UI
   registerOnChange(fn: any): void {
      this.onChange = fn
   }

   // Save a callback function that should be called when the control is touched
   registerOnTouched(fn: any): void {
      this.onTouched = fn
   }

   // Set whether the control is in a disabled state
   setDisabledState?(isDisabled: boolean): void {
      // TODO: Handle the disabled state here if needed
   }

   // Method to call when the input value changes
   handleChange(event: any): void {
      this.selectedOption = event
      this.value = this.selectedOption?.returnValue
      this.onChange(this.value)
      this.optionsExpanded = false
   }

   // Method to call when the input is touched
   handleBlur(): void {
      this.onTouched()
      this.optionsExpanded = false
   }

   isSelectedOption(option: any): boolean {
      return option.returnValue === this.selectedOption?.returnValue
   }
}

export interface SelectListOption {
   displayValue: string
   returnValue: any
}
