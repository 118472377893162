import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule, NgOptimizedImage } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { WindowEvent } from '../../enums'
import { IColumn } from '../interactive-report/interactive-report.component'
import { FormsModule } from '@angular/forms'

@Component({
   selector: 'app-report-slide-over',
   standalone: true,
   imports: [CommonModule, MatIconModule, FormsModule, NgOptimizedImage],
   templateUrl: './report-slide-over.component.html',
})
export class ReportSlideOverComponent {
   @Input() columns: IColumn[] = []
   @Output() windowEvent$: EventEmitter<WindowEvent> =
      new EventEmitter<WindowEvent>()
   @Output() reportConfigurationEvent$: EventEmitter<any> =
      new EventEmitter<any>() //Field selected, field order change, filter created, sort changed
   //
   // fields: field[] = [
   //    {
   //       internalName: 'mrn',
   //       displayName: 'MRN',
   //       dataType: 'number',
   //       align: 'align-baseline',
   //    },
   //    {
   //       internalName: 'pat_name',
   //       displayName: 'Patient Name',
   //       dataType: 'string',
   //       align: 'align-baseline',
   //    },
   //    {
   //       internalName: 'created',
   //       displayName: 'Case Create Date',
   //       dataType: 'date',
   //       align: 'align-center',
   //    },
   //    {
   //       internalName: 'num_action_items',
   //       displayName: '# Action Items',
   //       dataType: 'measure',
   //       align: 'align-center',
   //    },
   //    {
   //       internalName: 'link_to_case',
   //       displayName: 'Link to Case',
   //       dataType: 'link',
   //       align: 'align-center',
   //    },
   // ]
   protected readonly WindowEvent = WindowEvent
}

// type field = {
//    internalName: string
//    displayName: string
//    dataType: string
//    align: string
// }
