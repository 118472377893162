<!--Section Select and New Case button-->
<div class="flex flex-row justify-between content-baseline">
   <div class="flex flex-row">
      <p class="monarch-text-header mr-4 py-1.5">Viewing Cases For</p>

      <select
         id="location"
         [(ngModel)]="$selectedSectionID"
         (ngModelChange)="
            this.storageService.setLastViewedSectionID($selectedSectionID())
         "
         name="location"
         class="block rounded-md border-0 min-w-48 py-1.5 pl-3 pr-10 monarch-text ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600"
      >
         @for (section of accessibleSections; track $index) {
            <option [ngValue]="section.sectionID">{{ section.name }}</option>
         }
      </select>
   </div>
</div>

<div>
   <app-region-display-selector
      [tabs]="regionDisplayTabs"
      (onTabChange)="$activeTab.set($event.displayValue)"
   ></app-region-display-selector>
   <div>
      <div>
         @switch ($activeTab()) {
            @case ('Next Conference') {
               <app-home-tab-next-conference
                  [sectionID]="$selectedSectionID()"
               ></app-home-tab-next-conference>
            }
            @case ('Recently Viewed') {
               <app-home-tab-recently-viewed
                  [sectionID]="$selectedSectionID()"
               ></app-home-tab-recently-viewed>
            }
            @case ('Open Cases') {
               <app-home-tab-open-cases
                  [sectionID]="$selectedSectionID()"
               ></app-home-tab-open-cases>
            }
            @default {
               <app-home-tab-open-cases
                  [sectionID]="$selectedSectionID()"
               ></app-home-tab-open-cases>
            }
         }
      </div>
   </div>
</div>
