import {
   Component,
   effect,
   Input,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { Document, MonarchCaseTab } from '../../../shared/interfaces'
import { CaseManagementService } from '../../../services/case-management.service'
import {
   SimpleList,
   SimpleListComponent,
   SimpleListItem,
} from '../../../shared/components/simple-list/simple-list.component'
import { HttpEvent, HttpResponse } from '@angular/common/http'
import { FileUploadFormComponent } from './file-upload-form.component'

@Component({
   selector: 'app-tab-documents',
   standalone: true,
   imports: [CommonModule, FileUploadFormComponent, SimpleListComponent],
   templateUrl: './tab-documents.component.html',
})
export class TabDocumentsComponent implements OnInit, MonarchCaseTab {
   @Input({ required: true }) caseID!: number
   @Input({ required: true }) allowEditing: boolean = false
   $caseDocuments: WritableSignal<Document[]> = signal([])
   documents: Document[] = []
   documentDisplayList: SimpleList = []

   constructor(private caseManagementService: CaseManagementService) {
      effect(() => {
         this.documentDisplayList = this.$caseDocuments().map((x: Document) =>
            mapDocumentToListItem(x)
         )
      })
   }
   ngOnInit() {
      this.caseManagementService.getRelatedDocuments(this.caseID).subscribe({
         next: (value) => this.$caseDocuments.set(value),
      })
   }

   processDocumentUpload(e: Document) {
      this.documents.unshift(e)
   }

   downloadDocument(fileGUID: string) {
      this.caseManagementService
         .downloadDocument(this.caseID, fileGUID)
         .subscribe({
            next: (value) => {
               if (value instanceof HttpResponse) {
                  const blob = new Blob([value.body] as any)
                  console.log(value.headers)
                  const contentDisposition = value.headers.get(
                     'Content-Disposition'
                  )
                  console.log(contentDisposition)
                  let fileName = fileGUID // If not filename present on the disposition header, then fallback to the GUID
                  if (contentDisposition) {
                     const matches = /filename="(.+?)"/.exec(contentDisposition)
                     if (matches && matches[1]) {
                        fileName = matches[1]
                     }
                  }
                  let downloadLink = document.createElement('a')
                  downloadLink.href = URL.createObjectURL(blob)
                  downloadLink.setAttribute('download', fileName)
                  document.body.appendChild(downloadLink)
                  downloadLink.click()
                  downloadLink.parentNode!.removeChild(downloadLink)
               }
            },
         })
   }
}

function mapDocumentToListItem(d: Document): SimpleListItem {
   let createDate = new Date(d.uploadedAt)
   return {
      id: d.documentID,
      id_string: d.documentGUID,
      title: d.documentName,
      description: '',
      propertyPairs: [
         {
            key: 'CREATEDATE',
            display: 'Uploaded',
            value: createDate.toLocaleString(),
         },
         {
            key: 'CREATEDBY',
            display: 'By',
            value: d.uploadedBy,
         },
         {
            key: 'SIZE',
            display: 'Size',
            value: d.fileSizeHumanReadable,
         },
      ],
   }
}
