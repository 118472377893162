<div>
   <div class="flex flex-row justify-between pb-2">
      <div class="flex flex-row">
         <p class="monarch-text-header mr-4 py-1.5">Schedule for</p>

         <select
            id="location"
            name="location"
            class="block rounded-md min-w-48 border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
         >
            @for (section of accessibleSections; track $index) {
               <option [ngValue]="section.sectionID">{{ section.name }}</option>
            }
         </select>

         <p class="text-2xl font-bold leading-7 text-gray-900 mx-4 py-1.5">
            on
         </p>

         <select
            [(ngModel)]="selectedConferenceID"
            (change)="getCasesForSelectedConference()"
            id="conferenceID"
            name="location"
            class="block rounded-md min-w-48 border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
         >
            @for (conference of conferenceList; track $index) {
               <option [value]="conference.conferenceID">
                  {{ conference.startTime | date }}
               </option>
            }
         </select>
      </div>
      <div>
         <app-button
            label="New Conference"
            (click)="navigateToNewConferenceForm()"
         ></app-button>
      </div>
   </div>
   <hr />
   <div>
      @if (conferenceSchedule.length > 0) {
         <app-case-list [caseList]="conferenceSchedule"></app-case-list>
      } @else {
         <app-no-data-found
            title="No Agenda"
            subTitle="There are no Cases scheduleld for the selected Conference."
         >
         </app-no-data-found>
      }
   </div>
   <router-outlet></router-outlet>
</div>
