import { Component, forwardRef, Input } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
   selector: 'app-toggle',
   standalone: true,
   imports: [],
   templateUrl: './toggle.component.html',
   styleUrl: './toggle.component.css',
   providers: [
      {
         provide: NG_VALUE_ACCESSOR,
         useExisting: forwardRef(() => ToggleComponent),
         multi: true, // Allows Angular to use this as a ControlValueAccessor
      },
   ],
})
export class ToggleComponent implements ControlValueAccessor {
   @Input() labelOn: string = 'On' // Optional labels
   @Input() labelOff: string = 'Off'
   value: boolean = false
   disabled: boolean = false

   private onChange: (value: boolean) => void = () => {}
   private onTouched: () => void = () => {}

   // Called by Angular when the value changes in the form
   writeValue(value: boolean): void {
      this.value = value
   }

   // Registers a change callback
   registerOnChange(fn: (value: boolean) => void): void {
      this.onChange = fn
   }

   // Registers a touched callback
   registerOnTouched(fn: () => void): void {
      this.onTouched = fn
   }

   setDisabledState(isDisabled: boolean) {
      this.disabled = isDisabled
   }

   // Toggles the value
   toggle(): void {
      if (this.disabled) {
         return
      }
      this.value = !this.value
      this.onChange(this.value) // Notify Angular form of the change
      this.onTouched() // Mark as touched
   }
}
