import {
   Component,
   inject,
   OnInit,
   signal,
   WritableSignal,
} from '@angular/core'
import { ActionTabComponent } from '../../../../../shared/components/layout/action-tab/action-tab.component'
import { InteractiveTableComponent } from '../../../../../shared/components/table/interactive-table.component'
import {
   Capability,
   InteractiveTableColumn,
} from '../../../../../shared/interfaces'
import { AdminService } from '../../../../../services/admin.service'
import { ButtonComponent } from '../../../../../shared/components/button/button.component'

@Component({
   selector: 'app-dropdown-config-preventability',
   standalone: true,
   imports: [ActionTabComponent, InteractiveTableComponent, ButtonComponent],
   templateUrl: './dropdown-config-preventability.component.html',
})
export class DropdownConfigPreventabilityComponent implements OnInit {
   adminService = inject(AdminService)
   levelOfPreventabilityOptions: Capability[] = []
   $levelOfPreventabilityFormVisible: WritableSignal<boolean> = signal(false)
   $levelOfPreventabilityIsProcessing: WritableSignal<boolean> = signal(false)

   // TODO: Should fetch in parallel
   ngOnInit() {
      this.getLevelOfPreventabilityOptions()
   }

   getLevelOfPreventabilityOptions() {
      this.$levelOfPreventabilityIsProcessing.set(true)
      this.adminService.getCapabilities('PreventabilityLevel').subscribe({
         next: (value) => (this.levelOfPreventabilityOptions = value.slice(0)),
         complete: () => this.$levelOfPreventabilityIsProcessing.set(false),
      })
   }

   standardizedColumns: InteractiveTableColumn[] = [
      {
         key: 'id',
         displayValue: 'ID',
         isVisible: true,
         sortIndex: 0,
         dataType: 'number',
         headerAlignment: 'center',
         cellAlignment: 'center',
      },
      {
         key: 'name',
         displayValue: 'Title',
         isVisible: true,
         sortIndex: 1,
         dataType: 'string',
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
      {
         key: 'description',
         displayValue: 'Description',
         isVisible: true,
         sortIndex: 2,
         dataType: 'number',
         headerAlignment: 'left',
         cellAlignment: 'left',
      },
   ]
}
