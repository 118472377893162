import {
   Component,
   EventEmitter,
   Input,
   OnInit,
   Output,
   WritableSignal,
} from '@angular/core'
import {
   FormBuilder,
   FormsModule,
   ReactiveFormsModule,
   Validators,
} from '@angular/forms'
import { InputNumberComponent } from '../../../../shared/components/controls/input-number/input-number.component'
import { InputTextComponent } from '../../../../shared/components/controls/input-text/input-text.component'
import { ButtonComponent } from '../../../../shared/components/button/button.component'
import { ModalComponent } from '../../../../shared/components/modal/modal.component'
import { UserService } from '../../../../services/user.service'
import {
   NotificationService,
   NotificationType,
} from '../../../../services/notification.service'
import { User } from '../../../../shared/interfaces'
import { ToggleComponent } from '../../../../shared/components/controls/toggle/toggle.component'

@Component({
   selector: 'app-user-form',
   standalone: true,
   imports: [
      FormsModule,
      ReactiveFormsModule,
      InputNumberComponent,
      InputTextComponent,
      ButtonComponent,
      ModalComponent,
      ToggleComponent,
   ],
   templateUrl: './user-form.component.html',
})
export class UserFormComponent implements OnInit {
   constructor(
      private fb: FormBuilder,
      private userService: UserService,
      private notificationService: NotificationService
   ) {}

   @Input({ required: true }) $formIsDisplayed!: WritableSignal<boolean>
   @Output() onSubmitEmitter: EventEmitter<User> = new EventEmitter()

   userForm = this.fb.group({
      userID: [{ value: null, disabled: true }],
      email: [null, [Validators.required, Validators.email]],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      isInstanceAdmin: [false],
   })

   ngOnInit() {
      // TODO: Need to fetch values when editing a record
      if (this.userForm.get('userID')?.value) {
         this.userForm.get('userID')?.disable()
      }
      // this.userForm.events.subscribe({
      //    next: (value) => console.log(value),
      // })
   }

   submit() {
      if (this.userForm.valid) {
         const formValues = this.userForm.value
         this.userService
            .createUser(
               formValues.email!,
               formValues.firstName!,
               formValues.lastName!
            )
            .subscribe({
               next: (value) => {
                  this.onSubmitEmitter.emit(value)
                  this.$formIsDisplayed.set(false)
               },
               error: (error) =>
                  this.notificationService.new(NotificationType.Error, error),
            })
      }
   }
}
