<div>
   <div class="flex flex-row h-full">
      <app-region-display-selector
         [tabs]="regionTabs"
         [isVertical]="true"
      ></app-region-display-selector>
      <div class="ml-3 container overflow-auto">
         <router-outlet></router-outlet>
      </div>
   </div>
</div>
