<!--Tabbed Navigation-->
@if (isVertical) {
   <div class="h-full border-r border-gray-200">
      <nav
         class="flex flex-col flex-wrap w-48 space-y-1 pr-2 pt-2"
         aria-label="Tabs"
      >
         @for (tab of tabs(); track $index) {
            <a
               [ngClass]="
                  tab.isSelected
                     ? 'bg-gray-100 text-indigo-600'
                     : 'text-gray-800 hover:bg-gray-50 hover:text-gray-900'
               "
               (click)="selectTab(tab)"
               class="block rounded-md py-2 px-3 font-medium text-sm text-gray-500"
               >{{ tab.displayValue }}
            </a>
         }
      </nav>
   </div>
} @else {
   <div class="border-b border-gray-200">
      <nav class="flex flex-wrap pb-3 pt-2" aria-label="Tabs">
         @for (tab of tabs(); track $index) {
            <a
               [ngClass]="
                  tab.isSelected
                     ? 'bg-gray-100 text-indigo-600'
                     : 'text-gray-800 hover:bg-gray-50 hover:text-gray-900'
               "
               (click)="selectTab(tab)"
               class="block rounded-md py-2 px-3 font-medium text-sm text-gray-500"
               >{{ tab.displayValue }}
            </a>
         }
      </nav>
   </div>
}
